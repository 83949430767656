// .FlucelvaxPageTemplate .prod-sbs__img {
//   // margin-bottom: 150px;
//   margin-top: 200px !important;
// }

.FlucelvaxPageTemplate .prod-sbs__key-features-header {
  background-color: #f1f8e8 !important;
}

.FlucelvaxPageTemplate h3.prod-sbs__header.header--3 {
  line-height: 1.2;
  color: #659628 !important;
}
.FlucelvaxPageTemplate{
  p.aqiv-last-text{
    position: relative;
    right: 243px;
    width: 553px;
  }
}
.FlucelvaxPageTemplate .prod-sb.prod-sbUK {
  background-color: #1D8912 !important;

}

.FlucelvaxPageTemplate section.prod-sb {
  background-color: #1D8912 !important;
}

.FlucelvaxPageTemplate .logo--card.center-xs {
  display: none;
}

// .FlucelvaxPageTemplate img.hero--circlechart-chart {
//   width: 55% !important;
//   margin-left: 35%;
// }



.FlucelvaxPageTemplate .global_dashheader {
  font-size: 24px;
  font-family: "Campton-Medium", sans-serif;
}

.FlucelvaxPageTemplate h2 {
  font-family: "Campton-Medium", sans-serif;
  font-size: 36px !important;
  /* font-weight: bolder; */
  letter-spacing: 0;
  margin-bottom: 30px;
  line-height: 1.2;
  padding: 5% !important;
  color: #ffffff !important;
}

// .FlucelvaxPageTemplate .afluria-card {
//   margin: 20%;
// }

.FlucelvaxPageTemplate a.no-underline.text-dark-gray.cta.f-sb {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  // left: 55%;
  // top: 45px;
}

.FlucelvaxPageTemplatep .aqiv-first {
  margin-left: 0%;
  min-width: 65%;
}

.FlucelvaxPageTemplate p.aqiv-first {
  padding: 3%;
}

.FlucelvaxPageTemplate .col-xs-12.col-md-5.no-left-pad {
  display: none;
}

.FlucelvaxPageTemplate .aqiv-second-none,
.FlucelvaxPageTemplate .aqiv-second {
  font-family: 'Campton-SemiBold';
  font-size: 24px;
  line-height: 34px;
  color: #4D7B14 !important;
}

.FluadPageTemplate .aqiv-second-none {
  display: none;
}

.FluadPageTemplate .aqiv-second-none,
.FluadPageTemplate .aqiv-second {
  font-family: 'Campton-SemiBold';
  font-size: 24px;
  line-height: 34px;
  color: #407E96 !important;
}

.FlucelvaxPageTemplate .prod-tabs__tab-nav button.active span {

  // position: relative;
  &:before {
    content: "";
    // background-color: #4D7B14 !important;
    background-color: #f99d25 !important;
    position: absolute;
    left: 0;
    height: 90 !important;
    /* width: 4px !important; */
    z-index: 9;
    opacity: 1;
    height: 18%;
    width: 1% !important;
  }
}

// .FlucelvaxPageTemplate a.no-underline.text-dark-gray.cta.f-sb {
//   display: none;
// }

.FlucelvaxPageTemplate strong {
  font-family: 'Campton-SemiBold';
  font-size: 14px;
  line-height: 24px;
  // color: #4D7b14;
}

.FlucelvaxPageTemplate .aqiv-fourth-content-italic {
  font-family: 'Campton-Book';
  font-size: 14px;
  line-height: 21px;
  color: #5D6F7B;
  font-style: italic;
}

.FlucelvaxPageTemplate ul li {
  font-size: 20px;
  line-height: 40px;
}

// .FluadPageTemplate ul li {
//   font-size: 21px;
//   line-height: 40px;
// }

.FlucelvaxPageTemplate {
  .prod-aqiv-third {
    display: none !important;
  }
}

.FluadPageTemplate {
  .prod-qivc-third {
    display: none !important;
  }
}

.FlucelvaxPageTemplate {
  .featured-resources p {
    width: 100% !important;
    max-width: 100% !important;
  }

  .aqiv-fourth-content-italic,
  .aqiv-fourth-content,
  .aqiv-fourth-small {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.FlucelvaxPageTemplate .aqiv-fourth-content-list {
  display: flex !important;
}

.FlucelvaxPageTemplate span.greenNumber {
  color: #4D7b14;
  font-weight: bold;
}

.FlucelvaxPageTemplate p.prod_colHeader.M20,
.FlucelvaxPageTemplate p.prod_colHeader {
  font-weight: bold;
}

.FlucelvaxPageTemplate p.aqiv-fourth-content-italic {
  font-family: 'Campton-Book' !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #5D6F7B !important;
  margin-bottom: 15px !important;
  font-style: normal !important;
}

.FlucelvaxPageTemplate ul li {
  font-size: 18px;
  line-height: 27px;
}

.FlucelvaxPageTemplate strong {
  font-family: "Campton-Bold", sans-serif;
  font-size: 14px !important;

  line-height: 24px;
  // color: #4D7b14;
  // color: #4D7b14;
}

.FlucelvaxPageTemplate .blueText {
  color: #4D7B14 !important;
}
body.loggedin:has(app-second-banner) .FlucelvaxPageTemplate {
  .afluria {
    .vaccine-portfolio-row {
      display: flex !important;
      justify-content: center;
      padding-bottom: 70px !important;
      .col-xl-12 {
        display: none;
      }
    }

    .afluria-card {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .hero--circlechart-chart {
    height: 160px;
  }

  .vaccine-portfolio-row .col-xl-12 {
    display: none;
  }

  .disclaimer--paragraph {
    display: none;
  }

  .referencesContainerHomeUK {
    padding-bottom: 20px !important;
    padding-left: 52px !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    color: #5D6F7B !important;
  }

  @media (min-width: 1024px) {
    .page-references {
      .container {
        width: 100% !important;
        max-width: 100%;
        padding-left: 52px;
        padding-right: 52px;
        padding-top: 10px;
      }

      .reference {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  @media (min-width: 1024px) {
    .page-references .reference {
      line-break: anywhere;
    }
  }

  .containerUK {
    padding: 0 !important;
  }

  .referencesContainerUK {
    padding: 0 !important;
  }

  .aqiv-image {
    display: none;
  }

  .aqivtextPageContent1 {
    display: none;
  }

  .aqiv-second br{
    display: none !important;
  }

  .no-right-pad {
    width: 100%;
    max-width: 100% !important;
  }

  // .M20 {
  //   display: none;
  // }
  .prod-sbs__key-features-header{
    display: block !important;
  }
  .aqiv-second-subheader{
    font-family: 'CamptonSemiBold';
  }
  .aqiv-second-subtext{
    font-family: 'Campton-Book';
    font-size: 14px;
    line-height: 21px;
    color: #5D6F7B;
  }
  .aqiv-fourth-content-list li,.aqiv-fourth-content li{
    font-family: 'Campton-Book';
    font-size: 16px;
    line-height: 24px;
    color: #5D6F7B;
    margin-bottom: 15px;
    padding-left: 15px !important;
    width: 100% !important;
  }
 .aqiv-fourth-content-italic li{
  font-family: 'Campton-Book';
    font-size: 16px;
    line-height: 24px;
    color: #5D6F7B;
    margin-bottom: 15px;
    width: 100% !important;
 }
 .aqiv-fourth-content p{
  font-size: 16px;
    line-height: 24px;
 }
 .aqiv-fourth-content br{
  display: none;
 }
 .aqiv-fourth-content-italic p{
  font-family: 'Campton-Book';
    font-size: 14px;
    line-height: 21px;
    color: #5D6F7B;
    font-style: italic;
 }
 .aqiv-fourth-content-list {
  font-family: 'CamptonSemiBold';
    font-size: 14px;
    line-height: 24px;
    ul{
      padding-left: 15px !important;
    }
 }
.aqiv-fourth-content ul {
  padding-left: 15px !important;
}

.aqiv-fourth-content-italic ul {
  padding-left: 15px !important;
}

.fluad-container {
  padding-bottom: 30px !important;
}

.aqiv-fourth-content-italic strong {
  font-family: 'CamptonSemiBold' !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.center-text {
  text-align: center;
  margin-top: 16%;

  a {
    font-family: 'CamptonSemiBold';
    margin-left: 0px;
    color: #2A3237;
    font-size: 16px;

  }

  a:hover {
    text-decoration: underline !important;
  }

  img {
    margin-left: 10px !important;
  }
}

.content_para {
  ul {
    padding-left: 15px !important;

    li {
      margin-bottom: 10px !important;
    }
  }
}

.prod-tabs .container {
  padding: 0% 25px 0% 52px !important;
}

.prod-sbs {
  padding-top: 9%;
}

.prod-tabs__body .aqiv-first {
  font-size: 16px !important;
}

.first-row .col-md-7,
.second-row .col-md-7 {
  padding: 0;
  letter-spacing: 0;
}

.first-row {
  .aqiv-second-none {
    font-family: 'Campton-SemiBold';
    font-size: 24px !important;
    line-height: 34px;
    color: #4D7B14;
  }
}

.aqiv-fourth-content-list .greenNumber {
  font-size: 14px !important;
}

.aqiv-fourth-content .greenNumber {
  font-size: 14px !important;
}

.no-pad {
  padding: 0 !important;
  right: 12px;
}

.second-row img {
  height: auto !important;
  margin: 7rem 1rem !important;
}

.second-row .col-md-5 {
  padding: 0 !important;
}

.aqiv-logo {
  display: none;
}

.hero--prod__logoUK img {
  width: 63% !important;
}

.first-row .qivc-image {
  padding: 0 !important;
}
.prod-sb__right h2{
  font-size: 32px !important;
}
.prod-sb__leftUK img{
  width: 82% !important;
}
#opeffuiency-third{
  padding: 0% 25px 0% 52px !important;
}
.vaccine-portfolio-row{
  padding: 0% 25px 0% 52px !important;
}
 div#home_dashleftpadding {
  margin-top: 10%;
  margin-bottom: 4%;
}
.hero--prod__info .greenLine{
  height: 34px;
  width: 5px;
  float: left;
  margin-right: 20px;
  margin-top: -5px;
  background-color: #FF9E1B;
}
.summaryofproductuk{
  position: relative;
  //right: 247px !important;
  cursor: pointer !important;
}
.productextra{
  color: #5D6F7B !important;
  position: relative;
  //right: 242px;
}
}
button#nav-tab-0:active{
  background-color: white !important;
}
.FlucelvaxPageTemplatep .aqiv-last-text{
  position: relative;
  right: 247px;
  width: 589px;
}