.LandingPage2Template {
    @media (min-width: 1024px) {
    #home_solutionssection #home_solutiontabs .solutions--btn {
        display: none;
    }
  }
}

.LandingPage1Template {
    @media (min-width: 1024px) {
        #home_leftcontent {
            position: relative;
            bottom: 17%;
            left: 1%;
            .overViewCrumbs p {
                margin-bottom: 6%;
                margin-top: 6%;
            }
            .allproducts_heading-overView { 
                padding-top: 2%;
                width: 110%;
                span {
                    color: #ea181b;
                    font-family: "Campton-Bold";
                }
            }
            .modified-h1 {
                margin-bottom: 1%;
                .global_Lshape {
                    transform: scale(1.3);
                }
            }
        }
        #home_leftcontenttext {
            width: 72%;
            margin-top: 0 !important;
        }
        #home_loginsection {
            img {
                height: fit-content;
                left: 4%;
                bottom: 18px;
                clip-path: inset(12px 0 10px 0);
                transform: scale(1.2);
                top: 32px;
            }
        }
        #home_solutionssection {
            margin-top: 0 !important;
            bottom: 17%;
            #home_solutionheader {
                font-size: 28px;
            }
            #home_solutionsubheader {
                font-size: 18px;
            }
            #home_solutiontabs {
                .home_tabs {
                    padding-bottom: 5% !important;

                    .home_tabsimg {
                        position: relative;
                        bottom: 14px;
                        height: 78px;
                        width: 50%;
                        margin-bottom: 0;
                    }
                    .home_tabsheader {
                        font-size: 24px;
                        position: relative;
                        bottom: 23px;
                    }
                    .home_tabscontent {
                        font-size: 18px;
                        position: relative;
                        bottom: 21px;
                    }
                }
            }
        }
        #vaccine-row {
            bottom: 33%;
            position: relative;
            .flu-vaccine--callout {
                width: 80%;
            }
        }
        #laptop-row {
            position: relative;
            bottom: 30%;
            .laptopParent .laptop-paragraph-content h2 {
                margin-bottom: 2%;
            }
            .dashlapimage img {
                top: 13% !important;
                transform: scale(1.2);
                bottom: 13%;
                left: 1%;
            }
        }
        #multiple-needs-header {
            margin-top: 15px;
            .section-spacing {
                .whatif--card-content {
                    width: 112%;
                }
            }
        }
        .section-spacing {
            #what-if-row {
                .center-content {
                    .whatif--card:nth-child(3) {
                        .content {
                            width: 250px;
                        }
                    }
                }
            }
        }
        #home_flu360overview {
            padding-bottom: 6.5% !important;
            .overview__2x2--cell {
                position: relative;
                width: 100%;
                left: 38%;
                bottom: 114px;
                a button {
                    font-size: 16px;
                }
            }
        }
    }
    .overview-multiple-needs-container{
       .section-spacing{
        width: 100%;
        max-width: 100%;
       }
       #what-if-row{
        margin-right: 0;
        margin-left: 0;
       }
    }
}
.AccountPageTemplate app-help-section-dashboard{
position: relative;
    padding-top: 0px;
    padding-bottom: 1px;
}
.AccountPageTemplate app-hero-overview{
    padding-top:66px;
}
.LandingPage1Template .content--expand.oversectionUK.expand--content strong{
    
    font-family: "Campton-Bold", sans-serif !important;
    color: #5d6f7b !important;
    font-size: 18px;
    line-height: 27px;
}
ul{
    font-family: "Campton-Book", sans-serif;
    font-size: 18px;
    line-height: 27px;
    color: #5d6f7b;
    padding-left:10px;
}
    .LandingPage1Template app-homepage-solution{
        padding-bottom: 54px;
    }

    .seqirusb2b-flu360 .AccountPageTemplate app-news-announcement-component {
        .dashborad_readmore {
            a:hover {
                text-decoration: underline !important;
            }
        }
    }