//All-products 
.AllProductsPageTemplate {
  .home_container {
    display: none;
  }

  .all-produts-main_inner-wrapper {
    display: block !important;
  }

  .hero--grad {
    padding-bottom: 70px;
    margin-bottom: 0;
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    background-color: #fff;
    min-height: 360px;
    width: 100%;
  }

  .AllProductsPageTemplate .hero--grad .breadcrumbs--page-nav {
    left: 0;
    background: transparent;
    //padding-left: 2%;
  }

  .hero--grad .breadcrumbs--page-nav {
    left: 0;
    background: transparent;
  }

  .breadcrumbs--page-nav {
    position: relative;
    // top: 0px;
    // left: 0px;
    height: 47px;
    // background: #ffffff;
    margin-bottom: 0;
  }

  .hero--grad .container {
    padding-left: 3%;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 1005;
  }

  .hero--grad .container {
    background-color: transparent;
  }

  .breadcrumbs--page-nav p {
    font-size: 14px;
    color: #5d6f7b;
    font-family: "Campton-Book", sans-serif;
    // padding-top: 12px;
    // margin-left: 25px;
  }

  .breadcrumbs--page-nav p strong {
    font-family: "Campton-SemiBold", sans-serif;
    font-weight: bold;
  }

  .hero--grad .row-flex {
    margin-right: 0;
    margin-left: 0;
  }

  .hero--grad .hero--grad__mobile {
    position: relative;
    // display: block;
    background-color: #f9fafa;
    overflow: hidden;
  }

  @media (min-width: 1024px) {
    .AllProductsPageTemplate .hidden-md {
      display: none !important;
    }
  }

  .AllProductsPageTemplate .hero--grad__content {
    // padding-top: 5em;
    padding-top: 6em;
    margin-left: -20px;
    // width: 60%;
    width: 71%;
    ;
    //padding-left: 3%;
  }

  .hero--grad__content {
    // padding-top: 5em;
    padding-top: 6em;
    margin-left: -20px;
    width: 71%;
  }

  .hero--grad .hero--grad__content .content-container {
    padding-left: 20px;
  }

  .content-container.content-container--has-corner {
    position: relative;
  }

  .hero--grad .hero--grad__content h1 {
    font-family: "Campton-Medium", sans-serif;
    font-weight: 40px;
    line-height: 51px;
    width: 550px;
  }

  .LO-hero--grad__content .text--grey-100 {
    color: #5d6f7b;
    letter-spacing: 0;
  }

  .LI-hero--grad__content .text--grey-100 {
    color: #5d6f7b;
    font-family: "Campton-Book";
  }

  p {
    font-family: "Campton-Book", sans-serif;
  }

  .content-container.content-container--has-corner:before {
    content: url('../images/rectangle-down.svg');
    left: 0;
    top: -12px;
    position: absolute;
  }

  .LI-hero--grad__content {
    width: 60% !important;
  }

  .all-produts-main_inner-wrapper {
    margin-bottom: 30px;

  }

  //second
  .product-3col__item strong {
    font-family: "Campton-Bold", sans-serif;
    font-weight: bold;
  }

  .disclaimer--paragraph {
    font-size: 16px;
    font-family: 'CamptonSemiBold';
    letter-spacing: 0;
  }

  .hero--grad__mobile img {
    width: 100%;
    max-width: 100%
  }

  #influenza-burden-hero {
    margin-bottom: 40px !important;
    height: 229px !important;
    margin-left: 0 !important;
    overflow: hidden !important;
  }

  #influenza-burden-hero .line {
    margin-top: 0;
    padding-left: 22px !important;
    margin-bottom: 30px;
  }

  #influenza-burden-hero a {
    font-size: 14px !important;
    padding-left: 23px !important;
  }

  .alertbox {
    position: relative !important;
  }
}


///media queries
@media (min-width: 1025px) {
  .AllProductsPageTemplate .breadcrumbs--page-nav {
    top: 2em;
    // background: transparent;
    z-index: 3;
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .AllProductsPageTemplate .breadcrumbs--page-nav p {
    padding-top: 0;
    margin-left: 0;
    line-height: 27px;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .AllProductsPageTemplate .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .AllProductsPageTemplate h1 {
    font-size: 40px;
    line-height: 51px;
  }
}

@media (min-width: 1025px) {
  .AllProductsPageTemplate p {
    font-size: 18px;
    line-height: 27px;
  }
}

.AllProductsPageTemplate p {
  font-family: "Campton-Book", sans-serif;

  strong {
    font-family: "campton-Bold";
  }
}

// .AllProductsPageTemplate{
//   .product-3col__item h3{
//     font-family: "campton-medium" !important;
//   }
// }

.AllProductsPageTemplate .content-container.content-container--has-corner:before {
  content: url('../images/rectangle-down.svg');
  left: 0;
  top: -12px;
  position: absolute;
}

.AllProductsPageTemplate .LI-hero--grad__content {
  width: 60% !important;
}

.AllProductsPageTemplate .all-produts-main_inner-wrapper {
  margin-bottom: 0;
}

//second
@media(min-width:1024px) {
  .AllProductsPageTemplate {
    .productsmobile {
      display: none !important;
      padding-bottom: 70px;
    }

    .col-xs-12.home_topfirst.home_stretch {
      display: none;
    }

    img.js-responsive-image {
      position: relative;
      height: 256px;
      // width: auto;
      width: 100%;
      transform: translateX(-50%);
      left: 50%;
    }

    .allproducts_heading {
      width: 100% !important;
    }

    #home_leftcontenttext {
      width: 100% !important;
    }
  }

  .productsmobile .col-md-8 {
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .AllProductsPageTemplate {
    .hero--grad {
      background-image: none !important;
    }

    .hero--grad .container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .hero--grad__content {
      padding-top: 0em;
    }
  }
}

@media (max-width: 1023px) and (max-width: 1023px) {
  .AllProductsPageTemplate .hero--grad .col-flex-md-8 {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 920px) {
  .AllProductsPageTemplate .hero--grad .hero--grad__content {
    width: 100% !important;
  }
}

@media screen and (min-width: 678px) and (max-width: 768px) {
  .hero--grad .hero--grad__content {
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .AllProductsPageTemplate {
    h3.line {
      width: 100% !important;
    }

    .hero--grad-alt .col-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .AllProductsPageTemplate .hero--grad .hero--grad__content .content-container {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 920px) {
  .AllProductsPageTemplate .hero--grad .hero--grad__content h1 {
    font-size: 34px;
    width: 100%;
    line-height: 41px;
  }
}

@media(max-width: 991px) {
  .AllProductsPageTemplate .breadcrumbs--page-nav a {
    display: inline;
  }
}

//all-products 5th component
@media (min-width: 1025px) {
  .safety-info .sbs__body {
    display: flex;
  }
}

@media (min-width: 1025px) {
  .safety-info .sbs__left {
    padding-right: 12px;
  }
}

@media (min-width: 1025px) {

  .safety-info .sbs__left,
  .safety-info .sbs__right {
    flex: 0 0 50%;
  }
}

#safetyInfo .sticky_container strong {
  font-family: "Campton-Bold", sans-serif;
}

//for all products-forth component
@media (min-width: 1025px) {
  .AllProductsPageTemplate #influenza-burden-hero {
    background-image: url('/assets/images/cells-bg.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: calc(130%);
  }
}

@media (min-width: 768px) {
  .AllProductsPageTemplate #influenza-burden-hero .container .line {
    // max-width: 584px;
    max-width: 622px;
  }
}

@media(max-width:1024px) {
  .AllProductsPageTemplate #influenza-burden-hero {
    height: 284px !important;
  }

  .AllProductsPageTemplate .line {
    font-size: 28px !important;
    line-height: 32px !important;
  }
}

.AllProductsPageTemplate #influenza-burden-hero .line {
  margin-top: 0;
  padding-left: 50px !important;
  margin-bottom: 30px;
}

.AllProductsPageTemplate #influenza-burden-hero a {
  font-size: 14px !important;
  padding-left: 50px !important;
}

@media (min-width: 1025px) {
  .AllProductsPageTemplate .header--3 {
    font-size: 32px;
    line-height: 40px;
  }
}

// .AllProductsPageTemplate .loggedIn {
//   display: none !important;
// }

.hero span {
  font-family: "Campton-Bold", sans-serif;
  color: #ea181b;
}

.safety-info .container {
  // margin: 0;
  padding: 1% 3%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1650px;
}

@media (min-width: 1025px) {
  .sbs__right {
    padding-left: 12px;
  }
}

#influenza-burden-hero .line sup {
  font-size: large;
}

.hero--grad__content .hero sup {
  font-size: 10px;
  vertical-align: 8px;
}

@media (min-width: 992px) {
  .LI_allProduct {
    max-width: 100% !important;
  }
}

#safetyInfo .sticky_container p {
  font-size: 14px;
  // color: #4C4C4C;
  letter-spacing: 0;
  line-height: 21px;
}

#safetyInfo p {
  margin: 0 0 10px !important;
}

#safetyInfo .sbs__left a {
  text-decoration: underline !important;
}

.AllProductsPageTemplate {
  .LI_allProduct {


    .product-3col__body:first-of-type {
      border: none !important;

      .product-3col__item {
        padding-top: 0 !important;
        margin-top: -50px !important;
      }
    }

  }

  #influenza-burden-hero {
    .line {
      padding-left: 0 !important;
    }

    a {
      padding-left: 0 !important;
    }
  }
}






//all-products first
// .AllProductsPageTemplate .home_tivbtn {
//   display: none;
// }

// .AllProductsPageTemplate #home_leftcontenttext {
//   width: 71% !important;
// }

// .AllProductsPageTemplate .hero--grad .hero--grad__content h1 {
//   font-family: "Campton-Medium", sans-serif;
//   font-weight: 40px;
//   line-height: 51px;
// }

// .AllProductsPageTemplate .allproducts_heading {
//   width: 68% !important;
// }

// .AllProductsPageTemplate #home_loginsection {
//   display: flex;
//   margin-bottom: 0;
//   background-size: contain;
//   background-position: center right;
//   background-color: #fff;
// }

// .AllProductsPageTemplate .breadcrumbs-for-products {
//   display: block !important;
//   font-size: 14px;
//   color: #5d6f7b;
// }

// .AllProductsPageTemplate .homepageuserguideparent {
//   display: none;
// }

.AllProductsPageTemplate .referencesContainerHomeUK {
  font-size: 14px;
  color: #5D6F7B !important;
  font-family: 'Campton-Book';
  margin: 16px 0px;

  p {
    font-size: 14px;
    padding-left: 2%;

    strong {
      font-family: "Campton-Bold", sans-serif;
      font-weight: bold;
    }
  }
}

.AllProductsPageTemplate #influenza-burden-hero {
  padding-left: 2%;
}

body.loggedin .AllProductsPageTemplate {
  .referencesContainerHomeUK p {
    padding-left: 0;
  }

  app-about-references {
    background-color: #fff;
  }

  .LI-hero--grad__content {
    width: 60% !important;
    letter-spacing: 0;
    padding-left: 2%;
  } 
  .hero--grad__content .text-left h1{
    width: 100% !important;
    margin-bottom: 30px !important;
  }
  #influenza-burden-hero {
    display: none;
  }
}

//for uk
body:not(.loggedin):has(app-homepage-uk-pislot) .AllProductsPageTemplate {
  .hero--grad__content {
    width: 72% !important;
  }

  .content-container.content-container--has-corner:before {
    top: -8px !important;
  }

  .hero--grad .hero--grad__content h1 {
    padding-top: 5px;
  }

  @media (min-width: 768px) {
    #influenza-burden-hero .container .line {
      max-width: 584px !important;
      letter-spacing: 0;
    }
  }

  #influenza-burden-hero {
    .line {
      margin-bottom: 25px !important;
    }

    a {
      letter-spacing: 0;
    }
  }

  .page-references {
    .container {
      width: 100% !important;
      max-width: 100%;
      padding-left: 3%;
    }
  }

  .referencesContainerHomeUK {
    p {
      padding-left: 0 !important;
      letter-spacing: 0;
      line-height: 21px;
    }

    strong {
      color: #5d6f7b;
    }
  }

  .grbnumber {
    margin-bottom: 1% !important;
    font-weight: bold;
    font-weight: bold;
    padding: 0% 40px 0% 40px;
  }
}
//for uk
body.loggedin:has(app-homepage-uk-pislot) .AllProductsPageTemplate {
  .LI-hero--grad__content {
      width: 82% !important;
    }
  
    .LI_allProduct .product-3col__body:first-of-type .product-3col__item {
      margin-top: 0 !important;
    }
  
    .PibannerUk {
      margin-bottom: 40px;
    }
  
    app-first-comp:first-child {
      border-top: none !important;
    }
  
    .grbnumber {
      padding: 0% 52px 0% 38px;
    }
    .PibannerUk a:hover{
      text-decoration: none !important;
    }
}
//black border for allproductspage
body:not(.loggedin):has(app-homepage-uk-pislot) .AllProductsPageTemplate .BodyContent{
  border-top: 1px solid #ececec;
}
body.loggedin:has(app-homepage-uk-pislot) .AllProductsPageTemplate .BodyContent{
  border-top: 1px solid #ececec;
}
body.loggedin .AllProductsPageTemplate app-first-comp:nth-child(1){
  border-top: none !important;
}
.AllProductsPageTemplate .PibannerUk:hover{
text-decoration: none !important;
}