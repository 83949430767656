.RealWorldEvidencePageTemplate .product_bold ul li {
  font-size: 20px;
  line-height: 50px;
  margin-top: 20px;
}

.RealWorldEvidencePageTemplate .product_common-box.fluad-commonbox {
  background-image: linear-gradient(22.33deg, #095938 13.49%, #1d8912 83.42%) !important;
}

.RealWorldEvidencePageTemplate app-flucel-fifth {
  a.product_box1-button {
    display: none !important;
  }
}

//navigation

.RealWorldEvidencePageTemplate .product_efficacy-header {
  margin: 0px 0px 64px !important;
  background: linear-gradient(57.86deg, #3f961d 19.07%, #78be20 69.84%) !important;
  background-clip: text !important;
  left: 50%;
  position: relative;
  transform: translateX(-46%);
}

.product_efficacy-header {
  font-family: 'Campton-Bold';
  font-size: 48px;
  letter-spacing: 0;
  line-height: 52px;
  text-transform: uppercase;
  background: linear-gradient(22.3deg, #78be20 14.62%, #3f961d 84.42%) !important;
  background-clip: text;
  margin: 0px;
}


.RealWorldEvidencePageTemplate .product_common-box {
  background-image: linear-gradient(22.33deg, #095938 13.49%, #1d8912 83.42%);
}

.RealWorldEvidencePageTemplate a.blue-background,
.efficacyandSafetyPageTemplate a.blue-background {
  margin-right: 0px;
  font-family: 'CamptonSemiBold' !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  background-color: #FF9E1B !important;
  padding: 10px 20px;
  text-transform: uppercase;
  max-width: 172px;
}

.RealWorldEvidencePageTemplate.product_secondary-navigation a:nth-child(1) span {
  position: absolute;
  bottom: -22px;
  left: 0;
  border: 3px solid #FF9E1B !important;
  width: 116px;
}

.RealWorldEvidencePageTemplate .product_secondary-navigation a:first-child.active span,
.RealWorldEvidencePageTemplate .product_secondary-navigation a:first-child:hover span {
  position: absolute;
  border: 3px solid #FF9E1B !important;
  bottom: -22px;
  width: 50%;
  left: 0;
}

.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(2).active span,
.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(4).active span,
.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(2):hover span,
.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(4):hover span {
  position: absolute;
  border: 3px solid #FF9E1B !important;
  bottom: -22px;
  width: 70%;
  left: 0;
}

.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(3).active span,
.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(3):hover span {
  // width: 116px;
  position: absolute;
  border: 3px solid #FF9E1B !important;
  bottom: -22px;
  width: 70%;
  left: 0;
}
.RealWorldEvidencePageTemplate label {
  color: #4C4C4C;
  font-family: 'CamptonSemiBold';
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  // margin-bottom: 10px;
}

.RealWorldEvidencePageTemplate .panel_body-red {
  background: linear-gradient(22.3deg, #d5390a 14.62%, #872102 84.42%) !important;
  background-clip: text !important;
  color: transparent;
  font-family: 'Campton-SemiBold';
}

.RealWorldEvidencePageTemplate .product_textcontent.product_headercontent.product-years {
  font-size: 20px !important;
  letter-spacing: 0;
  line-height: 30px;
  // color: #4C4C4C;
  font-family: 'CamptonSemiBold';
}

.FlucelvaxPageTemplate .product_box1-text2-gray strong {
  margin-top: 60px;
  padding: 21px 20px 19px;
  text-align: center;
  width: 100%;
  color: #000000;
  font-family: "CamptonSemiBold";
  font-size: 23px;
  letter-spacing: 0;
  line-height: 28px;
  background-color: #ededee;
}



.FlucelvaxPageTemplate .product_middleline {

  height: 2px;

  background-color: #000;

  margin-bottom: 20px;

}

.FlucelvaxPageTemplate .product_circles {

  display: flex;

  justify-content: space-between;

}

.FlucelvaxPageTemplate .product_outer-circle {

  width: 30px;

  height: 30px;

  border-radius: 50%;

  background-color: #ccc;

  cursor: pointer;

}

.product_outer-circle.active {

  background-color: #000;

}

.RealWorldEvidencePageTemplate {
  .fluad_imageside {
    display: none;
  }

  .panel_body-bottomLeft div:nth-child(1) {
    font-family: 'Campton-Bold';
    font-size: 64px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 64px;
    margin-bottom: 10px;
    background: linear-gradient(22.3deg, #1d8912 14.62%, #78be20 84.42%);
    background-clip: text;
    color: transparent;
  }

  .panel_body-bottomLeft div:nth-child(2) {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    font-family: 'CamptonSemiBold';
    // width:67%;

  }
}

.efficacyandSafetyPageTemplate .container.clinical-flucelvax-breadcrumbs {
  position: relative;
  right: 2%;
  bottom: 20%;
}

.RealWorldEvidencePageTemplate {
  app-flucelvax-referenceparagraph {
    .cbsanimationbelow {
      div p {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0;
      }
    }
  }

 
}

.RealWorldEvidencePageTemplate .product_text.product_text1.active {
  color: #000;;
}

.RealWorldEvidencePageTemplate .product_text.product_text2.active {
  color: black;
}

.RealWorldEvidencePageTemplate .product_text.product_text3.active {
  color: black;
}

.RealWorldEvidencePageTemplate .product_efficacy-text {
  width: 90%;
  position: relative;
  left: 60px;
}

.RealWorldEvidencePageTemplate {
  .product_efficacy-text strong {
    font-family: 'Campton-Bold' !important;
  }
}
.RealWorldEvidencePageTemplate .sticky_container {
  background-color: #fff;
}

.RealWorldEvidencePageTemplate h1.product_efficacy-header {
  left: 592px;
  position: relative;
  top: 20px;
}

.RealWorldEvidencePageTemplate .product_image-textSide-content {
  div {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    line-break: auto;
    color: #000;
  }
}
.RealWorldEvidencePageTemplate {
  .product_image-textSide-content li {
    font-size: 20px;
    line-height: 28px;
    margin-top: 25px;
    color: #000;
    font-family: "Campton-Book";
  }
}

.RealWorldEvidencePageTemplate app-flucelvax-referenceparagraph {
  position: relative;
  top: 53px;
}

.RealWorldEvidencePageTemplate {
  .product_common-box-content li {
    width: 70%;
  }

  .product_box1-disclaimer.disclaimer--paragraph {
    font-family: 'Campton-Book' !important;
  }
}

.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(2).active span,
.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(4).active span,
.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(2):hover span {
  position: absolute;
  border: 3px solid #FF9E1B !important;
  bottom: -22px;
  width: 70%;
  left: 0;
}

.RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(4) span {
  position: absolute;
  border: 3px solid #FF9E1B !important;
  bottom: -22px;
  width: 70%;
  left: 0;
}

.RealWorldEvidencePageTemplate .product_text .product_text3 {
  position: relative !important;
}

body.loggedin .RealWorldEvidencePageTemplate .breadcrumbs--page-nav.hidden-xs.hidden-sm {
  display: none !important;
}

.RealWorldEvidencePageTemplate .content div {
  width: 80%;
  position: relative;
  top: 13px;
}

.RealWorldEvidencePageTemplate .content strong {
  font-family: 'Campton-Bold' !important;
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active {
  position: relative;
  left: 5%;
  bottom: 30px;
}
.RealWorldEvidencePageTemplate .flucelvax-top-navigation img.js-responsive-image{
  width: 100% !important;
  max-width: 100% !important;
  left: 0 !important;
}
.RealWorldEvidencePageTemplate img.js-responsive-image {
  position: relative;
  left: 85px;
}
body.loggedin .RealWorldEvidencePageTemplate .flucelvax-top-navigation img.js-responsive-image {
  position: relative;
  right: 0 !important;
}
body.loggedin .RealWorldEvidencePageTemplate .simple-banner.banner__component--responsive.common-image{
  width: 100% !important;
  right: 0 !important;
}
body.loggedin .RealWorldEvidencePageTemplate app-fluadchoosenow{
.product_common-box.fluad-commonbox{
  margin-top: 0px !important;
}
}
.RealWorldEvidencePageTemplate {
  @media only screen and (min-width: 1024px) {
    .breadcrumbs--page-nav {
      top: 0.5em !important;
      background: transparent;
      z-index: 999;
      width: 100%;
      position: relative;
      /* left: 1%; */
    }
  }
}

.RealWorldEvidencePageTemplate .product_image-textSide-Image {
  position: relative;
  right: 90px;
}

.RealWorldEvidencePageTemplate .product_realworld-whitebox {
  position: relative;
  width: 1200px;
  right: 8px;
  top: 23px;
}

.RealWorldEvidencePageTemplate .panel_body-bottomRight {
  position: relative;
  bottom: 37px;
}

.RealWorldEvidencePageTemplate .product_image-textHeader {
  color: #000000;
  font-family: 'Campton-Bold';
  font-size: 36px !important;
  letter-spacing: 0;
  line-height: 40px !important;
  text-align: center;
  text-transform: uppercase;
}

.RealWorldEvidencePageTemplate .product_image-textHeader div {
  position: relative;
  bottom: 26px;
}

.RealWorldEvidencePageTemplate img.js-responsive-image.young-girl {
  position: relative;
  top: 100px;
  left: -14px;
}

.RealWorldEvidencePageTemplate app-flucel-fifth {
  .product_box1-disclaimer {
    width: 100%;
    color: #4C4C4C;
    font-family: 'Campton-Book';
    font-size: 14px !important;
    letter-spacing: 0;
    position: relative;
    bottom: 40px;
    width: 106%;
    line-height: 21px !important;
  }
}

.RealWorldEvidencePageTemplate {
  .product_image-textSide-content p strong {
    font-family: 'Campton-Bold' !important;
  }

  .RealWorldEvidencePageTemplate .product_common-box.fluad-commonbox img.js-responsive-image1.box {
    min-width: 546px !important;

  }

  .RealWorldEvidencePageTemplate .product_common-box-Image1 {
    position: relative;
    right: 29px;
  }
}

.RealWorldEvidencePageTemplate .product_common-boxHeader {
  width: 70% !important;
}

.RealWorldEvidencePageTemplate .bottom_footer p {
  color: #5d6f7b;
}

.RealWorldEvidencePageTemplate {

  .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active {
    position: relative;
    left: 55px;
  }

  .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold1 {
    width: 77%;
    padding: 1px;
  }

  .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight div {
    width: 93%;
    top: 20px;
  }
}

.RealWorldEvidencePageTemplate .cbsanimationbelow {
  background: #fff !important;
}

.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight label {
  position: relative;
  top: 3px;
}

.RealWorldEvidencePageTemplate a.product_download-button {
  position: relative;
  left: 40px;
  bottom: 40px;
}

body.loggedin .RealWorldEvidencePageTemplate app-flucel-fifth {
  .product_box1-disclaimer {
    width: 100%;
    color: #4C4C4C;
    font-family: 'Campton-Book';
    font-size: 14px !important;
    letter-spacing: 0;
    position: relative;
    bottom: 40px;
    width: 111%;
    line-height: 21px !important;
  }
}

body.loggedin .RealWorldEvidencePageTemplate app-flucel-fifth {
  .product_image-textSide-container img.js-responsive-image.young-girl {
    top: -164px;
  }
}

.RealWorldEvidencePageTemplate .panel_body-bottom .panel_body-bottom3 .real-world_bodyBottom.active .panel_body-bottomLeft div {
  position: relative;
  left: 70px;
}

.RealWorldEvidencePageTemplate .product_circle.product_circle1 {
  position: relative;
  left: 50px;
}

//loggedin

body.loggedin .RealWorldEvidencePageTemplate .simple-banner.banner__component--responsive.common-image {
  width: 500px;
  position: relative;
  right: 85px;
}

body.loggedin .RealWorldEvidencePageTemplate img.js-responsive-image {
  position: relative;
  right: 40px;
  // max-width: 400px;
}

body.loggedin .RealWorldEvidencePageTemplate app-flucel-fifth {
  img.js-responsive-image.young-girl {
    position: relative;
    top: 29px;
    left: -69px;
  }
}

body.loggedin .RealWorldEvidencePageTemplate .product_realworld-whitebox {
  width: 986px;
  height: 1100px;
}

body.loggedin .RealWorldEvidencePageTemplate app-real-world-comp {
  .product_image-textSide-Image img.js-responsive-image {
    width: 1052px;
    height: 1330px;
    position: relative;
    left: 90px;
  }
}

body.loggedin .RealWorldEvidencePageTemplate .product_realworld-whitebox {
  padding: 0px 20px 49px !important;
  position: relative;

}

body.loggedin .RealWorldEvidencePageTemplate .RealWorldEvidencePageTemplate .panel_body-bottomLeft div:nth-child(1) {
  width: 300px;
  padding: 25px;
  right: 14px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight {
  position: relative;
  bottom: 3px;
}
body.loggedin .RealWorldEvidencePageTemplate .product_download-button {
  background: #3F961D;
  color: #FFFFFF !important;
  font-family: "CamptonSemiBold";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 15px 20px 15px 20px;
  display: block;
  width: max-content;
bottom:36px;
  margin-left: 30px;
  position: relative;
  // top: -3px !important;
  left: 10px;

}

body.loggedin .RealWorldEvidencePageTemplate app-flucel-fifth {
  .product_image-textSide {
    margin-top: 18px !important;
  }
}

body.loggedin .RealWorldEvidencePageTemplate {
  .panel_body-bottomLeft {
    position: relative;
    bottom: 60px;
  }

  .panel_body-bottomDivider {
    left: 0px !important;
    width: 17px;
  }
}

body.loggedin .RealWorldEvidencePageTemplate {

  h1.product_efficacy-header {
    position: relative;
    left: 490px;
    top: 83px !important;
  }

  .product_efficacy-text {
    position: relative;
    width: 841px;
    right: 20px;
    top: 49px;
  }

  .product_returnSliderparent {
    margin-top: 60px;
  }
  .product_circle.product_circle2{
    position:relative;
    left:28px;
  }
}
.RealWorldEvidencePageTemplate app-flucel-fifth {
  img.js-responsive-image.young-girl {
    position: relative;
    top: -14px;
  }
}
body.loggedin .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active{
  margin-top: 10px;
  position: relative;
    bottom: 26px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom.real-world_bodyBottom.active .panel_body-bottomRight .bold2{
  // bottom: 10px !important;
  top:-13px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight .bold5 {
  width: 96%;
  // line-break:anywhere;
    top: 44px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold2 {
  top: 17px;
  position:relative;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold1 {
  position: relative;
  top: 32px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold1 strong{
  font-family: 'Campton-Bold';
}
.bodyloggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold5 {
  position: relative;
  bottom: 29px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold {
  position: relative;
  top: 46px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight .bold2 {
  top: 27px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight .bold1 {
  position: relative;
  top: 42px;
}

body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight .bold {
  position: relative;
  top: 58px;
}

body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomDivider{
  left:10px !important;
  margin-left:8px !important;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomLeft{
  right:13px;
}
body.loggedin .real-world_bodyBottom.active .panel_body-bottomLeft div:nth-child(1) {
  font-family: 'Campton-Bold';
  font-size: 64px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 64px;
  margin-bottom: 1px;
  background: linear-gradient(22.3deg, #1d8912 14.62%, #78be20 84.42%);
  background-clip: text;
  color: transparent;
}

.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active {
  padding: 5px 10px 20px;
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomLeft {

  width: 263px !important;
  position: relative;
  left: 33px;
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomDivider{
  position: relative;
  left: 134px;
  width: 7px;
}
.RealWorldEvidencePageTemplate{
   .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight{
    margin-left: 68px !important;
   }
   .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active.panel_body-bottomRight .bold1{
    position: relative;
    top: -1px;
   }
   .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom .panel_body-bottomLeft .bold4{
    color: #707372 !important;
    font-family: 'Campton-Book';
    font-size: 20px !important;
    letter-spacing: 0;
    line-height: 28px !important;
    position: relative;
    bottom: 20px;
   }
   .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom .panel_body-bottomLeft .bold4{
    color: #707372 !important;
    font-family: 'Campton-Book';
    font-size: 20px !important;
    width: 297px;
    position: relative;
    bottom: 28px;
   }
   .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom .panel_body-bottomLeft .bold4{
    color: #707372 !important;
    font-family: 'Campton-Book';
    font-size: 20px !important;
    width: 297px;
    position: relative;
    bottom: 28px;
    left:36px;
   }
   .panel_body-green{
    color: #02652A;
    font-family: 'Campton-SemiBold';
   }
   
  .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight .bold2 ,.bold{
  color: #4C4C4C;
    font-family: 'CamptonSemiBold';
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;  
    position: relative;
    top: -9px;
}
.RealWorldEvidencePageTemplate .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight .panel_body-red{
  top: 8px;
  position:relative;
}
.panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight .bold1{
position: relative;
top: -4px !important;
}
.panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight{
position: relative;
    bottom:8px;
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight .bold{
top:10px !important;
}
.panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight{
  position: relative;
      bottom:8px;
  }
  .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold2{
  color: #4C4C4C;
    font-family: 'CamptonSemiBold';
    font-size: 22px;
    font-weight: 600;
    position: relative;
    top: 10px;
}
.panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight .bold2{
  color: #4C4C4C;
    font-family: 'CamptonSemiBold';
    font-size: 22px;
    font-weight: 600;
}
.panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold5{
  margin-top: 89px;
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight div{
  position: relative;
  top: 10px;
}
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .bold4{
  position: absolute;
  margin-bottom: 21px !important;
  margin-right:-21px
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .bold4{
  position: absolute;
   
    margin-right: -43px;
    width: 322px !important;
    margin-bottom: 29px !important;
    line-height: 30px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .bold4{
  position: absolute;
  /* margin-bottom: -19px !important; */
  margin-left: 4px;
  width: 322px !important;
 bottom:-11px;
  line-height: 30px;
}
.RealWorldEvidencePageTemplate  .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold{
  position: relative;
  top: 40px;
}
.RealWorldEvidencePageTemplate  .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold5{
position: relative;
bottom: 40px;
width:81%;
}
.RealWorldEvidencePageTemplate  .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomRight .bold1{
position: relative;
top: 25px;
}
.RealWorldEvidencePageTemplate  .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight .bold2{
position: relative;
bottom:0px !important;
top:-11px !important;
}
.RealWorldEvidencePageTemplate .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight .bold5{
  position: relative;
  top: 24px !important;
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active span.product_bold{
  font-family: 'Campton-Bold';
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active span.product_bold{
  font-family: 'Campton-Bold';
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight bold{
  top: 12px;
}
.RealWorldEvidencePageTemplate  .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight .bold{
  position: relative;
  top: 40px;
}
.RealWorldEvidencePageTemplate  .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight .bold5{
position: relative;
top: 55px;
}
.RealWorldEvidencePageTemplate  .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight .bold1{
position: relative;
top: 20px;
}
.RealWorldEvidencePageTemplate  .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight .bold2{
position: relative;
top: 10px;
}
.RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight strong{
  font-family: 'Campton-Bold';
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomLeft{
  position: relative;
    left: 11px;
    top:1px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomDivider{
  left: 49px !important;
  width: 10px;
  margin: 43px;
  height: 328px;
  top: 19px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom3.real-world_bodyBottom.active .panel_body-bottomRight {
  position: relative;
  bottom: 8px;
  top: 31px;
  right:29px;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active .panel_body-bottomDivider {
  left: 0px !important;
  width: 19px;
  height: 391px;
  bottom: 6px;
}
.RealWorldEvidencePageTemplate app-fluadchoosenow{
  .product_common-box.fluad-commonbox{
    margin-top: 40px !important;
  }
}
body.loggedin .RealWorldEvidencePageTemplate .content strong {
  font-family: "Campton-Bold" !important;
}
body.loggedin .RealWorldEvidencePageTemplate .product_secondary-navigation a:nth-child(4) span {
  position: absolute;
  border: 3px solid #FF9E1B !important;
  bottom: -22px;
  width: 87%;
  left: 0;
}
body.loggedin .RealWorldEvidencePageTemplate .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom1.real-world_bodyBottom.active .panel_body-bottomRight .bold {
  top: 11px !important;
}
body.loggedin .RealWorldEvidencePageTemplate .panel_body-bottom.panel_body-bottom2.real-world_bodyBottom.active.panel_body-bottomRight .bold1 {
  width: 98%;
  padding: 1px;
}
.RealWorldEvidencePageTemplate .safety-info.sbs{
  background-color: #fff !important;
}
.RealWorldEvidencePageTemplate .bottom_footer{
background: #F7F9F8 !important;
padding-left: 0px;
padding-right: 0;
position: relative;
}
body.loggedin .RealWorldEvidencePageTemplate .egg-icon{
  position: absolute;
    bottom: 90%;
    left: 311px;
}
body.loggedin .RealWorldEvidencePageTemplate
.bottom_footer{
  width: 1016px;
  padding: 14px;
}

body.loggedin.seqirusb2b-flu360 .RealWorldEvidencePageTemplate {
  app-real-world-comp {
    .product_image-textSide-Image img.js-responsive-image {
      width: 100% !important;
    }
    
    .product_realworld-whitebox {
      width: 100% !important;
    }

    .product_efficacy-header {
      left: 51% !important;
    }

    .product_efficacy-text {
      width: 93% !important;
    }
  }

  app-flucel-fifth {
    .product_image-textSide {
      .container {
        max-width: 1650px !important;
      }
      .product_image-textSide-content {
        width: 48% !important;
      }
     .product_image-textSide-Image .js-responsive-image.young-girl {
        top: 0px !important;
    }
    }
  }
}

.seqirusb2b-flu360 .RealWorldEvidencePageTemplate app-fluadchoosenow{
  .product_common-box-Image1 img.js-responsive-image1.box{
      width: 131%;
      max-width: 776px !important;
      float: none !important;
  }
}