.loggedin .RegistrationAccountTemplate .registration_leftside{display: none !important;} 
.loggedin .RegistrationAccountTemplate .registration_rightside {padding: 0px 12% 0px 60px !important;max-width: 94% !important;}
.RegistrationAccountTemplate .newresgistrationtitle{display: none !important;}
.RegistrationAccountTemplate .contactsubsection{display: none !important;}
.RegistrationAccountTemplate .log_Reg_Navigatesection{display: none !important;}
.RegistrationAccountTemplate app-custom-left-navigation{display: none;}
cx-page-layout.RegistrationAccountTemplate .BodyContentUK{width:100% !important;}
.loggedin cx-page-layout.RegistrationAccountTemplate .BodyContentUK {
  width: 83.333% !important;
}
.loggedin .RegistrationAccountTemplate app-custom-left-navigation{display: block !important;}
.loggedin .RegistrationAccountTemplate .newresgistrationtitle{display: block !important;}
.loggedin .RegistrationAccountTemplate .contactsubsection{display: block !important;}
.loggedin .RegistrationAccountTemplate .log_Reg_Navigatesection{display: flex !important;}
.loggedin .RegistrationAccountTemplate .nonweb{display: none !important;}
.RegistrationAccountTemplate .nonweb{display: block !important;}

.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
  opacity: 1 !important;
}

.seqirusb2b-uk.loggedin .registration_thankyou_leftside{display: none !important;} 
.seqirusb2b-uk.loggedin .fornonwebenabled{display: none !important;}
.seqirusb2b-uk.loggedin .forwebenabled{display: block !important;}
.seqirusb2b-uk .forwebenabled{display: none !important;}
.seqirusb2b-uk cx-page-layout.RegistrationAccountTemplate .BodyContent{width: 100% !important;}
.seqirusb2b-uk.loggedin cx-page-layout.RegistrationAccountTemplate .BodyContent{width: 83.333% !important;}