.adjuvantedFluadPageTemplate {
    .product_efficacy-header {
        background: linear-gradient(120.82deg, #00c2ff 0.95%, #3f961d 100.04%) !important;
        background-clip: text !important;
        color: transparent !important;
    }
    .product_common-box{
        margin-top: 0 !important;
    }
    .adjuvant-video{
        display: inline-block !important;
    }
    .adjuvantbasedvideo{
        .container{
            width: 100%;
            max-width: 100%;
        }
        .videocontainer{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90%;
            margin: auto;
        }  
        .no-padding {
            padding: 0 !important;
            margin-right: 0 !important;
        }
        .video-wrap {
            text-align: end;
            margin-top: 1%;
            video {
                width: 85%;
                vertical-align: baseline;
            }
        } 
        .watchsubtext{
          padding-left: 20px;
          width: 100% !important;
        }
        .watchtext{
            padding-left: 20px;
        }
    }
    
    .product_secondary-navigation a:hover span,
    .product_secondary-navigation a.active span{
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        left: 0;
    }
    .product_secondary-navigation .blue-un4:hover span,
    .product_secondary-navigation .blue-un4.active span{
        width: 59%;
    }
    .product_secondary-navigation .blue-un3:hover span,
    .product_secondary-navigation .blue-un3.active span{
        width: 84%;
    }
    .product_secondary-navigation .blue-un2:hover span,
    .product_secondary-navigation .blue-un2.active span{
        width: 74%;
    }
    .product_secondary-navigation .blue-un:hover span,
    .product_secondary-navigation .blue-un.active span{
        width: 84%;
    }
    .product_secondary-navigation .blue-un3 span{
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        left: 0;
        width: 84%;
    }
    #safetyInfo{
        background-color: #ffffff !important;
    }
    .bottom_footer{
        padding-bottom: 0 !important;
    }
    .bottom_footer_grey{
        display: block !important;
        background: #F7F9F8;;
        strong{
        font-family: "Campton-Bold", sans-serif;
        }
    }
    .bottom_footer{
        display: none !important;
    }
    .adjuvanted-heading{
        display: block !important;
    }
    .bottom_footer strong{
        font-family: "Campton-Bold", sans-serif !important;
        color: #5d6f7b !important;
    }
}
body:not(.loggedin) .adjuvantedFluadPageTemplate{
    .product_secondary-navigation .blue-un4:hover span,
    .product_secondary-navigation .blue-un4.active span{
        width: 45% !important;
    }
    .product_secondary-navigation .blue-un3:hover span,
    .product_secondary-navigation .blue-un3.active span{
        width: 72% !important;
    }
    .product_secondary-navigation .blue-un2:hover span,
    .product_secondary-navigation .blue-un2.active span{
        width: 57% !important;
    }
    .product_secondary-navigation .blue-un:hover span,
    .product_secondary-navigation .blue-un.active span{
        width: 68% !important;
    }
    .product_secondary-navigation .blue-un3 span{
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        left: 0;
        width: 72% !important;
    }
}
