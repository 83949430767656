.LandingPage1Template{

    margin-left: -0.5px !important;

    .end-hero-callout {
        margin: 20px 29% !important;
    }

    #home_solutionssection.home_solutionssection-uk {
        margin-top: 180px !important;
    }

    #vaccine-row {
        .flu-vaccine--callout {
            width: 87% !important;
        }
    }

    .home_loginsection-uk{
        min-height: 548px !important;
        padding-left: 1.5% !important;

        img{
            object-fit: cover;
            top: 44px !important;
            left: 76px !important;
            height: 87% !important;
            width: 95% !important;
            clip-path: inset(13px 0 0 0);
        }
    }

    .foruk_header {
        .for-healthcare-profe {
            margin-top: 0px !important;
        }
    }

    .home_container-uk{
        margin-bottom: -40px !important;
        border-top: 1px solid #DBE0E2;

        #home_loginsection img {
            clip-path: inset(24px 0 10px 0);
            top: 4% !important;
        }
    }


    .flu-vaccine-callout-uk{
        background-color: #f9fafa;
        height: 301px !important;

        .rectangle-up{
        left: 450px;
        }

        .headLine {
            width: 470px !important;
        }
    }
    .laptop{

        margin-bottom: -70px !important;
        .laptop-image{
        height: 86% !important;
        left: 60px !important;
        top: 52px !important;
        width: 87% !important;
        }

    }

    .list{
        padding-left: 15px !important;

        li{
            font-size: 17.9px;
        }
    }

    .end-hero-uk{

        .overview__2x2--cell{
            left: 31% !important;
            bottom: 98px !important;
        }

        .rectangle-down{
            left: -34px !important;
            top: -7px !important;

        }

       .rectangle-up{
            left: 317px !important;
             top: -23px !important;
        }

        p{
        position: relative;
        font-size: 32px;
        line-height: 40px;
        font-weight: 500;
        margin-left: 0px;
        bottom: 7.5px;
        right: 0.5px;
        letter-spacing: normal;
        }

        .create-btn{

            
            .button--solid-red{
                z-index: 1;
                top: 3px !important;
                left: 32.5% !important;
                width:16% !important;
                font-size: 16px !important;
                border: 1px solid #ea181b;
            }

            .button--solid-red:hover{
                background-color: #ffffff !important;
                color: #ea181b !important;
                cursor: pointer !important;
            }
    
    
        }
    
        
    }
    
    

    .gbr{

        position: relative;
        top: 12px;
        left: 10px;
        margin-bottom: 32px;

        .grbno {
            font-size: 14px;
            color: #5D6F7B;
            font-family: 'Campton-Book';
            margin-top: 388px;
        }
    }

    .overViewCrumbs-uk{
        margin-bottom: 19px !important;
        margin-left: -15px !important;
        position: relative !important;
        top: -32px !important;
        font-size: 13.5px !important;

        strong{
            font-family: 'CamptonSemiBold' !important;
            color: #5d6f7b !important;
        }
        
    }

    .home_leftcontent-uk{

        bottom: 6% !important;

        .allproducts_heading-overView{
            padding-top: 12px !important;
            font-size: 40px !important;
            line-height: 51px !important;
            padding-left: 2px !important;

            span{
                color: #ea181b !important;
                font-family: "Campton-Bold" !important;
            }
        }

        .global_Lshape{
            margin:-16px -15px !important; 
        }

        .global_horizontalL{
            width: 26px !important;
            height: 5px !important;
        }

        .global_verticalL{
            height: 23px !important;
            width: 5px !important;
        }

        #home_leftcontenttext{
            padding-left: 2px;
            width: 79%;
            font-size: 18px;
            margin-top: -4px;
        
        }

        .overView_tivbtn{
            padding: 15px 16px !important;
            width: 29%;
            font-size: 15px !important;
            font-family: 'CamptonSemiBold' !important;
            margin-left: 2px !important;
            border: 1px solid #ea181b !important;
            
        }

        .overView_tivbtn:hover{
            background-color: #ffffff !important;
            color: #ea181b !important;
            cursor: pointer !important;
        }

    }

    // .PibannerUk{
    //     font-family: "Open Sans", Helvetica, Arial, sans-serif;
    // }

    .PibannerUk:hover{
        text-decoration: none;
    }

    .home_solutionssection-uk{

        padding-left: 1.5% !important;
        padding-right: 0% !important;

        #home_solutionheader{
            text-align: center;
            font-size: 28px !important;
            margin-left: -14px !important;
        }

        #home_solutionsubheader{
            text-align: center;
            font-size: 18px !important;
        }

        .home_tabs-uk{
            flex: 0 0 29.6%;
            background-color: #F9FAFA !important;
            border-radius: 2% !important;
            padding: 10px 24px 25px !important;
            text-align: left !important;
            width: 33.33%;
        }

        #home_tabheader1 {
            color: #5D6F7B !important;
        }

        #home_tabheader2 {
            color: #3C827A !important;
        }

        .home_tabs-uk:nth-of-type(2) {
            margin-left: 4%;
            margin-right: 4%;
        }

        .home_tabsimg{
            height: 79px !important;
            margin-left: -17px !important;
            margin-top: -13px !important;
        }

        .home_tabsheader{
            font-size: 24px !important;
            margin-top: -7px !important;
        }

        .home_tabscontent{
            font-size: 18px !important;
            margin-top: 4% !important;
    
        }

        .oversectionUK{
            margin-top: 14px !important;

            p{
                font-family: 'Campton-Bold' !important;
                color: #5d6f7b !important;
                font-size: 18px !important;
            }

            ul{
                padding-left: 15px !important;
            }

            li{
                color: #5d6f7b !important;
                font-size: 18px !important;
                line-height: 27px !important;
            }
        }

        .card-expand{
            height: 516px !important;
        }
    
        .card-collapse{
            height:304px !important;
        }
    
        .solutions--btn{
            position: absolute;
            top: 25px;
            right: -9px;
            width: 50px;
            height: 50px;
            cursor: pointer;
            transform: scale(1.3);

            .collapse--btn{
                padding-top: 20px;
            }
        }
    
        .not-active{
            display: none !important;
        }
    }

    
    
    
    @media only screen and (max-width: 1054px) {
            .home_leftcontent-uk{
                padding-left: 18px !important;

                #home_leftheader{
                    width: 106%;
                }
    
                #home_leftcontenttext{
                    width: 87%;
                } 

            } 
        
    }

    

    @media only screen and (max-width: 1200px) {
        
        // .gbr{

        //     position: relative;
        //     top: -49px;
        //     left: 10px;
        //     margin-bottom: -20px;
    
        //     .grbno {
        //         font-size: 14px;
        //         color: #5D6F7B;
        //         font-family: 'Campton-Book';
        //     }
        // }

        // .flu-vaccine-callout-uk{
        //     .headLine{
        //         width: 115%;
        //     }

        //     .rectangle-up{
        //         left: 453px;
        //     }
        // }

        // .home_leftcontent-uk{
        

        //     #home_leftcontenttext{
        //         width: 77%;
        //     } 

        // } 

        

        
    }

    @media only screen and (max-width: 1400px) {
        // .home_solutionssection-uk{
    
        //     .home_tabs-uk{
        //         flex: 0 0 27.6%;

        //     }
    
        // }
        // .flu-vaccine-callout-uk{
        //     .headLine{
        //         width: 123%;
        //     }
        // }
        
    }

}

.seqirusb2b-uk {
    .LandingPage1Template {
        #laptop-row .dashlapimage img {
            top: 20% !important;
        }
    }
}