.fluadClinicalDataPageTemplate {
    .bottom_footer {
        display: flex !important;
    }

    .bottom_footer p:nth-child(2) {
        margin-left: 5px;
    }

    .bottom_footer strong {
        font-family: "Campton-Bold", sans-serif;
        font-weight: bold;
        color: #5d6f7b;
    }

    .product_secondary-navigation a:nth-child(3) span {
        position: absolute;
        bottom: -22px;
        left: 0;
        border: 3px solid #4ECFF8 !important;
        width: 116px;
    }

    .product_secondary-navigation a:first-child.active span,
    .product_secondary-navigation a:first-child:hover span {
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        width: 43%;
        left: 0;
    }

    .product_secondary-navigation a:nth-child(2).active span,
    .product_secondary-navigation a:nth-child(4).active span,
    .product_secondary-navigation a:nth-child(2):hover span,
    .product_secondary-navigation a:nth-child(4):hover span {
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        width: 70%;
        left: 0;
    }

    .product_secondary-navigation a:nth-child(3).active span,
    .product_secondary-navigation a:nth-child(3):hover span {
        width: 116px;
    }

    #productMenu .child-item:nth-child(3) {
        display: none;
    }

    .clinical-fluad-heading {
        display: block !important;
    }
    .alertbox{
        position: relative  !important;
    }
    #safetyInfo{
        background-color: #ffffff !important;
    }
    .bottom_footer{
        padding-bottom: 0 !important;
    }
    .bottom_footer_grey{
        display: block !important;
        background: #F7F9F8;;
        strong{
        font-family: "Campton-Bold", sans-serif;
        }
    }
    .bottom_footer{
        display: none !important;
    }
}

.product_image-textCenter.fluad_image-center .product_image-text1 li {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    font-family: 'CamptonSemiBold';
    margin-bottom: 25px;
}

.clinicalimgbanner .imagebannertext sup {
    font-family: 'Campton-Book'
}

@media (min-width: 1025px) {
    ul li {
        font-size: 18px;
        line-height: 27px;
    }
}

.product_image-text1 ul li {
    font-weight: 500;
}

.product_image-text1 ul,
ol {
    padding-left: 15px;
}

.clinical-fluad-first .product_clinical-text span,
.clinical-fluad-first .product_clinical-text sup {
    font-family: 'Campton-Book' !important;
}

.product_secondary-navigation .global_blackarrow {
    margin-left: 5px;
}

.blue-background span {
    color: #000000 !important;
    font-family: 'CamptonSemiBold' !important;
    font-weight: 600;
    font-size: 16px;
}

.product_common-box {
    .product_common-box-content ul {
        margin-top: 30px;
        margin-left: 11px;
        margin-bottom: 10px;
        padding-left: 15px;
    }

    .product_common-box-content ul li {
        color: #FFFFFF;
        font-family: 'Campton-SemiBold';
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 26px;
        margin-top: 10px;
        list-style: disc;
    }

    .product_common-box.fluad-commonbox .product_common-innerUL li {
        list-style: circle;
    }

    .product_common-box-content ul {
        margin-top: 30px;
        margin-left: 11px;
        margin-bottom: 10px;
        padding-left: 15px;
    }

    .product_common-box-content ul li {
        color: #FFFFFF;
        font-family: 'Campton-SemiBold';
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 26px;
        margin-top: 10px;
        list-style: disc;
    }

}

.product_common-box.fluad-commonbox .product_common-innerUL li {
    list-style: circle;
}

@media only screen and (max-width: 1023px) {
    .product_common-box .product_common-box-content ul li {
        font-size: 18px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 1023px) {
    .product_common-box .product_common-box-content ul li {
        font-size: 18px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 1023px) {
    .product_common-box .product_common-box-content ul li .product_common-innerUL {
        margin-top: 13px;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1023px) {
    .product_common-box.fluad-commonbox .product_box1-button {
        margin-bottom: 35px;
    }
}

@media only screen and (max-width: 1023px) {
    .product_common-box .product_box1-button {
        margin-top: 30px;
        font-size: 16px;
        line-height: 16px;
        // margin-bottom: 50px;
    }
}

@media only screen and (max-width: 1023px) {
    .product_common-box.fluad-commonbox .product_common-box-Image {
        width: 100%;
        margin-bottom: 32px;
        margin-top: 0px;
    }
}

#FluadClincialData ::ng-deep .mat-mdc-tab-header-pagination-controls-enabled {
    display: none;
}

#FluadClincialData ::ng-deep .mat-tab-group {
    flex-grow: 1 !important;
}

.product_common-box .product_common-box-content ul li ul li {
    margin-left: 20px;
}

.product_common-box .product_common-box-content .product_common-innerUL {
    margin-top: 13px;
}

@media (min-width: 992px) {
    .fluadClinicalDataPageTemplate .sbs__left .hidden-lg {
        display: none;
    }
}

@media (min-width: 768px) {
    .fluadClinicalDataPageTemplate .sbs__left .hidden-md {
        display: none;
    }
}

@media (max-width: 575.98px) {

    .fluadClinicalDataPageTemplate .sbs__left .hidden-xs,
    .fluadClinicalDataPageTemplate .sbs__right {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {

    .fluadClinicalDataPageTemplate .sbs__left .hidden-sm,
    .fluadClinicalDataPageTemplate .sbs__right {
        display: none;
    }
}
body.loggedin .fluadClinicalDataPageTemplate {
    .product_secondary-navigation a:nth-child(2):hover span{
        width: 86% !important;
      }
      .product_secondary-navigation a:nth-child(4):hover span{
          width: 85% !important;
        }
      .product_secondary-navigation a:nth-child(2).active span,
      .product_secondary-navigation a:nth-child(4).active span{
        width: 80% !important;
      }
      .product_secondary-navigation a:first-child.active span,
      .product_secondary-navigation a:first-child:hover span{
        width: 61% !important;
     }
}
/// flucelvax - cell based solution styles
.cellBasedSolutionPageTemplate {
    .product_common-box {
        margin-top: 220px;
        padding-bottom: 21px;
        background-image: linear-gradient(22.33deg, #095938 13.49%, #1d8912 83.42%) !important;
        margin-bottom: 80px;
    }

    .container {
        padding: 0% 3%;
    }

    .product_common-box .container {
        display: flex;
        justify-content: center;
    }

    .product_common-box .product_common-box-container {
        width: 82% !important;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .product_common-box .product_common-box-content {
        width: 43%;
    }

    .product_common-box .product_common-boxHeader {
        color: #FFFFFF;
        font-family: 'Campton-SemiBold';
        font-size: 36px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 42px;
        margin-top: 61px;
    }

    .product_common-box .product_common-box-content ul {
        margin-top: 30px;
        margin-left: 11px;
    }

    .product_common-box .product_common-box-content ul,
    .product_common-box .product_common-box-content ol {
        padding-left: 15px;
    }

    .product_common-box .product_box1-button {
        margin-top: 40px;
        font-family: 'CamptonSemiBold' !important;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        padding: 17px 20px 13px;

        border: 1px solid #ffffff;
        background-color: transparent;
        margin-bottom: 69px;
    }

    .product_common-box .product_common-box-Image {
        width: 56%;
        margin-top: -83px;
    }

    .product_common-box-Image img {
        width: 100%;
    }

    img {
        vertical-align: middle;
    }

    img {
        border: 0;
    }

    .product_common-box .product_box1-disclaimer {
        text-align: left;
        margin-top: 0px;
        color: #ffffff;
    }

    .product_box1-disclaimer {

        width: 100%;
        font-family: 'Campton-Book';
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
    }

    .product_common-box .product_common-box-content .product_common-innerUL {
        margin-top: 13px !important;
    }

    .product_common-box .product_common-box-content ul {

        margin-left: 11px !important;
    }

    .blue-background {
        margin-right: 0px;
        color: #000000 !important;
        font-family: 'CamptonSemiBold' !important;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        background-color: #FF9E1B !important;
        padding: 10px 20px;
        text-transform: uppercase;
        max-width: 172px;
    }

    .fluad-top-navigation .logocontainer,
    .fluad-top-navigation .mobilelogocontainer {
        display: none !important;
    }

    @media (min-width: 821px) {
        #cellBasedSolution .cell-base-desktop {
            display: block !important;
        }
    }

    @media (max-width: 820px) {
        #cellBasedSolution .cell-base-mobile {
            display: block !important;
        }
    }

    .product_secondary-navigation a:nth-child(2) span {
        position: absolute;
        border: 3px solid #FF9E1B;
        bottom: -22px;
        left: 0;
        width: 212px;
    }

    .product_secondary-navigation a:nth-child(2).active span,
    .product_secondary-navigation a:nth-child(2):hover span {
        width: 116px;
    }

    .product_secondary-navigation a:nth-child(1).active span,
    .product_secondary-navigation a:nth-child(3).active span,
    .product_secondary-navigation a:nth-child(4).active span,
    .product_secondary-navigation a:nth-child(1):hover span,
    .product_secondary-navigation a:nth-child(3):hover span,
    .product_secondary-navigation a:nth-child(4):hover span {
        position: absolute;
        border: 3px solid #FF9E1B;
        bottom: -22px;
        width: 70%;
        left: 0;
    }

    .chartdesbox span {
        background-image: linear-gradient(22.3deg, #d5390a 14.62%, #872102 84.42%);
        background-clip: text;
        color: transparent;
        background-clip: text;
    }

    .columndeshead strong {
        color: #4C4C4C;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
        font-weight: 400;
        font-family: "Campton-Bold";
        display: block;
    }

    .cell-based-heading {
        display: block !important;
    }

    .products-mobile-navigation .product_menu-Accordian.fluad_menu-accordian .fa {
        color: #FF9E1B !important;
    }

    .product_menu-Accordian .panel-default>.panel-heading {
        border-bottom:2px solid #FF9E1B !important;
    }

    #safetyInfo {
        .references {
            display: none;
        }

        .bottom_footer {
            strong {
                font-family: "Campton-Bold", sans-serif;
                font-weight: bold;
            }

            br {
                display: none;
            }

            p {
                font-family: "Campton-Book", sans-serif;
                color: #5d6f7b;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}

// .product_image-text1 ul li {
//     // font-family: "Campton-Book", sans-serif;
//     font-weight: 500;
//     // font-size: 18px;
//     // line-height: 26px;
//     // color: #5d6f7b;
// }
// .active-tab {
//     background-color: #4ECFF8 !important;
// }

// .inactive-tab {
//     background-color: #ffffff !important;
// }