.FluadPageTemplate {
    .bottom_footer {
        display: flex !important;
    }

    .bottom_footer p:nth-child(2) {
        margin-left: 5px;
    }

    .bottom_footer strong {
        font-family: "Campton-Bold", sans-serif;
        font-weight: bold;
        color: #5d6f7b;
    }

    .product_secondary-navigation a:nth-child(1) span {
        position: absolute;
        bottom: -22px;
        left: 0;
        border: 3px solid #4ECFF8 !important;
        width: 68px;
    }

    .product_secondary-navigation a:first-child.active span,
    .product_secondary-navigation a:first-child:hover span {
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        width: 68px;
        left: 0;
    }

    .product_secondary-navigation a:nth-child(2).active span,
    .product_secondary-navigation a:nth-child(3).active span,
    .product_secondary-navigation a:nth-child(3):hover span,
    .product_secondary-navigation a:nth-child(2):hover span {
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        width: 70%;
        left: 0;
    }
    .product_secondary-navigation .blue-un:hover span,
    .product_secondary-navigation .blue-un.active span{
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        width: 69%;
        left: 0;
    }

    .product_secondary-navigation a:nth-child(3).active span,
    .product_secondary-navigation a:nth-child(3):hover span {
        width: 116px;
    }

    #productMenu .child-item:nth-child(3) {
        display: none;
    }

    .clinical-fluad-heading {
        display: none;
    }

    .fluad-heading {
        display: block !important;
    }

    .flucelvax {
        display: none !important;
    }

    .flucelvax-firstvideo {
        display: none !important;
    }

    .flucelvax_imageside {
        display: none !important;
    }

    .flucelvax_image-center {
        display: none !important;
    }

    .product_image-textSide.fluad_imageside {
        background-color: #F7F9F8;
        padding: 104px 0px 200px !important;
        margin-right: 0;
        padding-top: 104px !important;
        padding-bottom: 188px !important;
        .container {
            width: 100%;
            max-width: 1650px;
            padding: 0 3%;
        }
    }

    .product_image-textSide.fluad_imageside .container {
        align-items: center;
        flex-direction: column;
    }

    .product_image-textSide.fluad_imageside .product_image-textSide-container {
        width: 88% !important;
        padding: 0px !important;
    }

    .product_image-textSide.fluad_imageside .product_image-textSide-content {
        width: 47% !important;
        padding-right: 50px !important;

        sup {
            white-space: nowrap;
        }
    }

    .product_box1-button.product_fluad-button {
        background-color: #0A7381;
        margin-right: 16px;
        left: 0 !important;
    }

    .product_box1-button.product_box1-button2 {
        background-color: transparent;
        border: 1.25px solid #707372;
        color: #707372 !important;
        left: 0 !important;        
    }

    .product_image-textSide.fluad_imageside .product_image-textSide-Image {
        width: 50%;
        padding-left: 70px !important;
        padding-top: 0 !important;
        padding-right: 0 !important;
    }

    .product_image-textSide.fluad_imageside .banner__component--responsive img {
        max-height: 370px !important;
        border-image: none !important;
        border-color: #4ECFF8;
        height: 100%;
        float: right;
        margin: 0 !important;
    }

    .product_common-box {
        margin-top: 0 !important;
    }

    .safety-info {
        background-color: #fff !important;
    }

    .fluad-firstcomponent {
        p {
            margin: 0 0 10px;
        }

        @media only screen and (min-width: 1024px) {
            .breadcrumbs--page-nav {
                top: 2em !important;
            }
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            .breadcrumbs--page-nav p {
                margin-left: 0px;
                padding-top: 0px;
            }

            .breadcrumbs--page-nav {
                .container {
                    margin: 0;
                }
            }
        }

        .breadcrumbs--page-nav {
            position: relative;
            top: 0px;
            left: 0px;
            height: 47px;
            background: #ffffff;
            margin-bottom: 0;

            .container {
                padding: 0% 3%;
                margin-left: 2%;
            }

            p {
                font-size: 16px;
                color: #4C4C4C;

                strong {
                    font-family: "Campton-SemiBold", sans-serif;
                }
            }
        }

        @media (min-width: 1025px) {
            .breadcrumbs--page-nav {
                top: 2em;
                background: transparent;
                z-index: 3;
                width: 100%;

                p {
                    margin-top: 0;
                    margin-left: 0;
                    line-height: 27px;
                }
            }
        }
    }

    .product_image-textCenter.fluad_image-center {
        margin-top: 34px;

        // img {
        //     border-image: none !important;
        //     border-image-slice: revert !important;
        //     border-style: none !important;
        //     max-width: 1440px !important;
        //     max-height: 1000px !important;
        // }

        // .banner__component--responsive img {
        //     border-image: none;
        //     border-image-slice: revert;
        //     border-style: none;
        //     max-width: 1440px;
        //     max-height: 1000px;
        //     border-width: 8px 0px 0px 0px;
        //     display: flex;
        //     margin: 0px auto;
        //     width: 100%;
        //     height: auto;
        // }

        .container {
            padding: 0 3%;
            width: 100%;
            max-width: 1650px;
        }

        .product_image-textcontainer {
            background-image: linear-gradient(120.82deg, #00c2ff 0.95%, #3f961d 100.04%) !important;
            width: 88% !important;
            margin: -25% auto 0px !important;
            padding: 90px 0px 70px !important;
            text-align: center;
        }

        .product_image-textHeader {
            display: flex;
            flex-direction: row;
            color: #fff;
            justify-content: space-evenly;
            align-items: center;
            text-transform: uppercase;
        }

        .product_imagePlus {
            font-size: 100px;
            line-height: 64px;
        }

        .product_image-text1 {
            flex-direction: row !important;
            text-align: left !important;
            padding: 40px 64px 0px !important;
            justify-content: space-evenly !important;

            .product_image-subtext1 {
                width: 48%;
                padding-left: 35px;
            }

            .product_image-subtext3 {
                width: 12%;
            }

            .product_image-subtext2 {
                width: 40%;
            }

            li {
                font-size: 22px;
                line-height: 30px;
                color: #fff;
                font-family: 'CamptonSemiBold';
                margin-bottom: 25px;
            }

        }

        .product_box1-disclaimer {
            text-align: left;
            width: 88%;
            margin: 16px auto;
            color: #4C4C4C;
            font-family: 'Campton-Book';
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
        }
    }

    @media only screen and (max-width: 1023px) {
        .product_image-textSide.fluad_imageside {
            padding: 40px 0px 96px;
            margin-top: 90px;

            .product_image-textSide-container {
                width: 100% !important;
            }

            .product_image-textSide-content {
                width: 100% !important;
                padding-right: 0px !important;
                font-size: 18px;
                color: #4C4C4C;
                font-family: "Campton-Book";
                letter-spacing: 0;
                line-height: 28px;
            }

            .product_box1-disclaimer {
                width: 100%;
                margin-top: 32px;
                text-align: left;
                color: #4C4C4C;
                font-family: 'Campton-Book';
                font-size: 14px;
                letter-spacing: 0;
                line-height: 21px;
            }

            .product_image-textSide-Image {
                width: 100%;
                padding-left: 0px;
                margin-top: 48px;
            }

            .banner__component--responsive img {
                max-height: revert !important;
                border-image: none !important;
                border-color: #4ECFF8;
                width: 100% !important;
                float: right;
                max-width: 100% !important;
                border-width: 0px 8px 0px 0px !important;
                border-style: solid !important;
                height: auto;
            }
        }

        .simple-banner.banner__component--responsive[_ngcontent-ng-c31122732] {
            padding: 0;
            margin-left: 0;
            width: inherit;
        }

        .product_image-textSide a.product_box1-button {
            margin-top: 32px;
            display: inline-block;
            background-color: #0A7381;
            margin-right: 16px;
            font-size: 16px;
            line-height: 16px;
            padding: 12px 20px 10px;
            color: #FFFFFF !important;
            font-family: 'Campton-SemiBold';
            letter-spacing: 0;
        }

        .product_image-textSide a.product_box1-button2 {
            margin-top: 16px !important;
            background-color: transparent;
            border: 1.25px solid #707372;
            color: #707372 !important;
            font-size: 16px;
            line-height: 16px;
            padding: 12px 20px 10px;
            display: inline-block;
        }

        .product_image-textSide .product_image-textHeader {
            font-size: 28px;
            letter-spacing: 0;
            line-height: 30px;
            margin-bottom: 32px;
            padding: 0;
            text-align: left;
            color: #000000;
            font-family: "Campton-Bold";
            text-transform: uppercase;
        }

        .product_image-textCenter.fluad_image-center {
            position: relative;
            margin-top: 0px;

            .banner__component--responsive img {
                border-image: linear-gradient(120.82deg, #4ecff8 0.95%, #c4d600 100.04%) !important;
                border-image-slice: 1 !important;
                border-style: solid !important;
            }

            .product_blue {
                color: #4ECFF8 !important;
            }

            .product_image-textcontainer {
                width: 100% !important;
                padding: 48px 0 40px !important;
                margin: -42% auto 0px !important;
            }

            .product_image-textHeader {
                flex-direction: column;
            }

            .product_image-subtext {
                text-align: left;
                margin-top: 15px;

                ul {
                    padding-left: 22px;
                }

                li {
                    font-size: 18px;
                    line-height: 24px;
                    text-transform: initial;
                    color: #fff;
                    font-family: 'CamptonSemiBold';
                    margin-bottom: 15px;
                }
            }

            .product_imagePlus {
                font-size: 90px;
                padding: 0px 130px 24px;
            }
        }

        .product_image-textCenter.fluad_image-center .product_image-textinside {
            position: absolute;
            top: 40px;
            font-family: 'Campton-Bold';
            font-size: 38px;
            line-height: 42px;
            color: #fff;
            text-transform: uppercase;
            padding: 0 3%;
        }
    }

    @media (max-width: 768px) {
        app-topnavigation {
            order: 1 !important;
        }

        app-first-animation:nth-child(1) {
            order: 2 !important;
        }

        app-first-animation:nth-child(2) {
            order: 3 !important;
        }

        app-fourth {
            order: 4 !important;
        }

        app-third-navbar {
            order: 5 !important;
        }

        app-flucel-fifth {
            order: 6 !important;
        }

        app-fluadchoosenow {
            order: 7 !important;
        }

        app-custom-saftey-info {
            order: 8 !important;
        }
    }

    .fluad_imageside {
        display: block !important;
    }

    .banner__component--responsive img {
        bottom: 0px !important;
        right: 0px !important;
    }

    .fluad_imageside .container {
        padding: 0% 3% !important;
    }

    .product_image-textSide-content {
        padding: 0 !important;
        right: 0 !important;
    }

    // .product_image-textHeader {
    //     padding: 0 !important;
    // }

    .product_image-textpara {
        padding: 0 !important;
    }

    .product_box1-disclaimer {
        padding: 0 !important;
    }

    .LI_allProduct .safety-info.sbs {
        height: auto !important;
    }

    .product_image-textSide {
        padding: 0% 25px 0% 30px !important;
    }

    .product_image-textSide .product_image-textSide-container {
        width: 90% !important;
    }

    .LI_allProduct .strong-tag {
        display: none;
    }

    #safetyInfo .bottom_footer .references {
        display: none;
    }

    .alertbox {
        position: relative !important;
    }

    .sticky_container {
        .hidden-lg {
            display: none;
        }
    }

    .fluad {
        .product_box1 {
            margin-top: 100px !important;
        }

        .container {
            padding: 0% 3% !important;
            width: 100% !important;
            max-width: 100% !important;
        }

        .product_box1 .container {
            display: flex;
            justify-content: center;
        }

        .product_box1-container {
            width: 88% !important;
            padding-left: 0 !important;
        }
        .product_box1-text{
            padding-right: 60px !important;
            padding-left: 10px !important;
        }
        // .banner__component--responsive{
        //     padding-top: 0 !important;
        // }
    }

    .fluad_imageside .banner__component--responsive {
        padding: 0 !important;
    }

    .fluad_imageside {
        .js-responsive-image {
            width: auto !important;
            float: right !important;
            // padding-left: 70px !important;
        }
        .product_image-textHeader{
            padding: 0 !important;
        }
        img.js-responsive-image{
            left: 0 !important;
        }
    }
    .fluad_image-center img{
        width: 100%;
        height: auto;
    }
    .bottom_footer{
        display: none !important;
    }
    .bottom_footer_grey{
        display: block !important;
        background: #F7F9F8;
        .gery-references{
            // padding-top: 20px;
            padding-bottom: 20px;
            strong{
                font-family: "Campton-Bold", sans-serif;
                color: #5d6f7b;
            }
        }
    }
    .bottom_footer_grey .container{
        // padding: 1% 3%;
        padding-right: 3%;
        padding-left: 3%;
        padding-top: 0% !important;
    }
    .safety-info p{
        color: #3c3c3c !important;
    }
    .flucelvax-disclaimer{
        display: none;
    }
    .product_secondary-navigation{
        a:hover {
            transform: none !important; /* Remove the transformation */
            transition: none !important;
        }
    }
    .product_box1-content a{
        padding: 16px 20px 14px;
        margin-top: 43px;
        display: inline-block;
        color: #FFFFFF !important;
        font-family: 'Campton-SemiBold';
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        background-color: #0A7381;
    }
}


body.loggedin .FluadPageTemplate {
    .fluad_imageside .container {
        padding: 0% 25px 0% 30px !important;
    }

    .product_image-textSide.fluad_imageside .product_image-textSide-container {
        width: 90% !important;
    }

    .product_image-textSide.fluad_imageside .product_image-textSide-content {
        width: 50% !important;
    }

    .product_image-textSide.fluad_imageside .product_image-textSide-Image {
        padding-left: 40px !important;
    }
    .product_secondary-navigation a:nth-child(2):hover span,
    .product_secondary-navigation a:nth-child(2).active span{
      width: 84% !important;
    }
    .product_secondary-navigation a:nth-child(4):hover span{
        width: 83% !important;
      }
    .product_secondary-navigation a:nth-child(4).active span{
      width: 80% !important;
    }
    .product_box1-content{
        padding-right: 0 !important;
    }
    .product_image-textCenter.fluad_image-center .product_image-textcontainer{
        width: 80% !important;
    }
    .product_image-textCenter.fluad_image-center .product_box1-disclaimer{
        width: 80% !important;
    }
}
.FluadPageTemplate button#nav-tab-0,
button#nav-tab-1,
button#nav-tab-2,
button#nav-tab-3 {
    height: 15%;
    padding: 24px 32px !important;

}

.FluadPageTemplate span.blacktriangle {
    color: black !important;
}

.FluadPageTemplate div#home_dashleftpadding {
    margin-top: 10% !important;
}

.FluadPageTemplate .referencesContainerHomeUK.grbnumber.margin-B20 {
    font-size: 14px;
    color: #5D6F7B;
    font-family: 'Campton-Book';
    margin-left: -1%;
}

.FluadPageTemplate button#nav-tab-0,
button#nav-tab-1,
button#nav-tab-2,
button#nav-tab-3 {
    height: 15%;
    padding: 24px 32px !important;
}

.FluadPageTemplate span.blacktriangle {
    color: black !important;
}

.FluadPageTemplate div#home_dashleftpadding {
    margin-top: 10% !important;
}

.FluadPageTemplate .referencesContainerHomeUK.grbnumber.margin-B20 {
    font-size: 14px;
    color: #5D6F7B;
    font-family: 'Campton-Book';
    margin-left: -1%;
}

.FluadPageTemplate .product_textcontent {

    display: flex;

    justify-content: space-between;

    margin-bottom: 10px;

}
app-fluad-rwe-fourth {
    .product_image-textSide-content {
      .product_bold {
        font-size: 20px;
        color: #000000;
      }
 
      div ul li {
        margin-top: 25px;
      }
    }
}