.seqirusaboutSection1-aboutPage {
    .hero--overview-content {
        h1.content {
            margin-left: -40px !important;
            margin-top: 110px !important;
          }

        @media (min-width: 1024px){
            padding-left: 3%;
          }
    
          @media (max-width: 1300px){
            width: 40% !important;
          }
    
          @media (min-width: 1300px){
            width: 47% !important;
          }
    }

    app-about-section1 {
        .about-us {
            width: 121% !important;
        }

        .rectangle-down {
            top: 100px;
            right: 40px;
        }

        .hero--overview {

         
          .button--hero-solid-red {
            margin-top: 0px;
    position: relative;
    bottom: 36;
          }
            @media (min-width: 1024px) {
                top: -35px;
            }
        }
    }
}

.seqirusaboutusoptionSection1-aboutPage {
    .carousel-bar-line {
        top: 255px !important;
      }
    
    .active-bar {
        bottom: -5px !important
    }

    .carousel-inner {
        p.carousel-content {
            margin-left: 98%;
      width:581px;
            padding-top: 100px !important;
          }

        .link-container {
            bottom: 85px;
            margin-left: 130%;
        }
    }

    .influenza-carousel-desktop-btn-container {
        @media (min-width: 1024px) and (max-width: 1150px ){
          margin-top: 24% !important;
        }
        @media (min-width: 1150px) and (max-width: 1250px ){
          margin-top: 22% !important;
        }
        @media (min-width: 1250px) and (max-width: 1400px ){
          margin-top: 20% !important;
        }
        @media (min-width: 1400px){
          margin-top: 16% !important;
        }
    
        @media (min-width: 1600px){
          margin-top: 12% !important;
        }
      }
}

.seqirusaboutusReferenceSection1-aboutPage {
    .about-ref {
        margin-left: 10px !important;
    }
}

.seqirusaboutusFLuworldSection1-aboutPage {
  .navigateLink {
    display: block !important;
    margin-left: 50px;
    margin-top: 40px;
  }
  
  app-first-comp {
    .left-column {
      z-index: 99;
    }
  }
}
.seqirusaboutSection1-aboutPage app-first-comp{
.carousel-inner img{
max-width: 40% !important;
padding-top: 2%;
}
}