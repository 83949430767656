body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
    .product_efficacy-header {
        margin-left: 8% !important;
    }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
    .product_efficacy-text {
        padding: 100px;
        position: relative;
        padding-bottom: 5% !important;
        right: 1% !important;
        bottom: 70px !important;
    }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{ 
        #mat-tab-content-1-1 img.js-responsive-image {
        position: relative;
        left: 58px !important;
        bottom: 73px;
    }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{ 
      #mat-tab-content-1-0 #panel_details-studyText2{
        display:none !important;
      }
    }
    body.loggedin .efficacyandSafetyPageTemplate .graph {
        position: relative;
        left: 0px !important;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .product_efficacy-box2 {
        margin-top: -120px !important;
        margin-left: 75px !important;
        width:1100px;
    }
    
    body.loggedin div#product_efficacy-tabContainer1 {
        position: relative;
        right: 28px;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .product_efficacy-header2 {
        position: relative !important;
        right: 4% !important;
        top:40px;
    }
    body.loggedin div#product_efficacy-tabContainer1{
        width:1000px;
    }
    body.loggedin .efficacyandSafetyPageTemplate img.js-responsive-image.graph {
        width: 672px !important;
        position: relative;
        left: 60px !important;
    }
    
    // body.loggedin .efficacyandSafetyPageTemplate .panel_details-button {
    //     position: relative !important;
    //     right: 65% !important;
    // }
    
    .efficacyandSafetyPageTemplate .bottom_footer {
        background: #F7F9F8 !important;
        padding-left: 2% !important;
    }
    
    .efficacyandSafetyPageTemplate .bottom_footer {
        position: relative;
        right: 3%;
    }
    
    .efficacyandSafetyPageTemplate .bottom_footer strong {
        color: #5d6f7b !important;
        font-family: "Campton-Bold", sans-serif !important;
    
    }
    
    .efficacyandSafetyPageTemplate .mat-mdc-tab-body-content {
        overflow: hidden;
    }
    
    .efficacyandSafetyPageTemplate .product_efficacy-box1 {
        position: relative;
        /* bottom: 20px; */
        margin-top: 30px;
    }
    
    .efficacyandSafetyPageTemplate img.js-responsive-image {
        position: relative;
        left: 88px !important;
        // width: 800px !important;
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-data-third{
    img.js-responsive-image {    
            position: relative;
            left: 194px !important;
    }
    }
    .efficacyandSafetyPageTemplate .simple-banner.banner__component--responsive.d-md-block.d-lg-block.d-none.d-sm-none {
        position: relative !important;
        // left: 70px !important;
    }
    
    .efficacyandSafetyPageTemplate .product_common-box-Image1 {
        position: relative;
        right: 70px;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .product_common-box-Image1 {
        position: relative;
        right: 42px;
    }
    
    .efficacyandSafetyPageTemplate .panel_body-Image.col-xs-12 {
        position: relative;
        right: 8%;
    }
    .efficacyandSafetyPageTemplate div#product_efficacy-tabContainer2{
        width: 1200px;
        position: relative;
        right: 26px;
    }
    // .efficacyandSafetyPageTemplate img.js-responsive-image1{
    //     max-width:650px !important;
    
    // }
    .efficacyandSafetyPageTemplate .product_box1-disclaimer.disclaimer--paragraph {
        font-family: 'Campton-Book' !important;
    
    }
    
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:nth-child(3) span {
        position: absolute;
        bottom: -22px;
        left: 0;
        border: 3px solid #FF9E1B !important;
        width: 116px;
    }
    
    .efficacyandSafetyPageTemplate {
        .panel_body-Image {
            .panel.simple-banner.banner__component--responsive {
                width: 550px !important;
            }
        }
    }
    
    .efficacyandSafetyPageTemplate img.js-responsive-image1.box {
        min-width: 550px !important;
        position: relative;
    }
    
    .efficacyandSafetyPageTemplate img.js-responsive-image.graph {
        width: 730px !important;
    }
    
    .efficacyandSafetyPageTemplate .panel_body-disclaimer.col-xs-12 {
        position: relative;
        left: 10%;
        width: 100%;
    }
    
    
    
    .efficacyandSafetyPageTemplate .panel_body-bottomDivider {
        position: relative;
        left: 2%;
    }
    
    
    
    .efficacyandSafetyPageTemplate .product_efficacy-box2 {
        padding-left: 10px;
    }
    
    .efficacyandSafetyPageTemplate .mat-mdc-tab-body.mat-mdc-tab-body-active {
        overflow-y: hidden;
        overflow-x: hidden;
    }
    
    .efficacyandSafetyPageTemplate #product_efficacy-tabContainer2 {
        .panel_body-disclaimer.col-xs-12.ng-star-inserted {
            // position: relative;
            // left:0;
            width: 1181px;
            left: -4%;
            letter-spacing: 0;
            bottom:2%;
            // margin-top: 40px;
        }
    }
    
    .efficacyandSafetyPageTemplate #product_efficacy-tabContainer2 {
        .product_efficacy-tabContainer .panel_body-disclaimer .col-xs-12.ng-star-inserted {
            padding: 6px;
            left: 100px;
            margin-top: 50px;
        }
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .product_efficacy-header2 {
        padding: 40px;
        position: relative;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .product_efficacy-text {
        margin-top: 0px !important;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .clinical-flucelvax-second .product_efficacy-text {
        padding: 0 !important;
        position: relative;
        padding-bottom: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .clinical-flucelvax-second .product_efficacy-header3 {
        position: relative;
        bottom: 40px;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate #product_efficacy-tabContainer2 {
        width: 970px;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .panel_body-bottomRight {
        max-width: 500px !important;
        right: 5% !important;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .second {
        margin-top: -80px;
    
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .panel_body-disclaimer.col-xs-12.ng-star-inserted {
        width: 970px;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .js-responsive-image1.box {
        position: relative;
        left: 30%;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate #product_efficacy-tabContainer2 {
        .panel_body-disclaimer.col-xs-12.ng-star-inserted {
            // position: relative;
            // left:0;
            width: 970px !important;
    
            letter-spacing: 0;
        }
    }
    
    body.loggedin .panel_body-bottomDivider {
        position: relative !important;
        left: 4% !important;
    }
    
    .efficacyandSafetyPageTemplate p.references {
        display: none;
    }
    
    body.loggedin .product_efficacy-box1 .product_efficacy-header2 {
        top: 40px !important;
    }
    
    body.loggedin .panel_body-indicator1 {
        width: 45% !important;
        position:relative;
        right:8% !important;
    }
    
    body.loggedin .panel_body-indicator2 {
        width: 45% !important;
        position: relative !important;
        // left: 197px !important;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .product_box1-text2-gray {
        position: relative;
        left: 5%;
    }
    
    // body.loggedin .efficacyandSafetyPageTemplate .panel_body-indicator1 {
    //     position: relative;
    //     left: 110px !important;
    // }
    
    body.loggedin .efficacyandSafetyPageTemplate .strong-tag {
        display: none !important;
    }
    
    .efficacyandSafetyPageTemplate .strong-tag {
        display: none !important;
    }
    
    .efficacyandSafetyPageTemplate section#safetyInfo {
        background-color: #fff !important;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate section#safetyInfo {
        background-color: #fff !important;
    }
    .efficacyandSafetyPageTemplate  #product_efficacy-tabContainer1 #mat-tab-content-0-1 .pop.active {
        top: 226px !important;
     
    }
    .efficacyandSafetyPageTemplate #mat-tab-content-0-1 .panel-body-text.col-xs-12 {
        display: none !important;
    }
    
    .efficacyandSafetyPageTemplate #mat-tab-content-0-1 .panel-body-studyDetails.col-xs-12 {
        position: relative;
        left: 50px;
    }
    
    .efficacyandSafetyPageTemplate #mat-tab-content-0-1 div#panel_details-anchor1 {
        margin-left: 55% !important;
    }
    
    // body.loggedin .efficacyandSafetyPageTemplate #mat-tab-content-0-1 .panel-body-studyDetails.col-xs-12 {
    //     position: relative;
    //     left: 50px;
    // }
    
    body.loggedin .efficacyandSafetyPageTemplate #mat-tab-content-0-1 div#panel_details-anchor1 {
        margin-left: 55% !important;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate .efficacyandSafetyPageTemplate img.js-responsive-image.tab-2 {
        left: -20px !important;
        width: 780px !important;
    }
    
    .efficacyandSafetyPageTemplate .product_efficacy-box2 #mat-tab-content-0-1 .panel_body-bottomRight {
        color: #4C4C4C;
        font-family: "Campton-Book";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        margin: auto;
        width: 95%;
        max-width: 670px;
        position: relative;
        right: 2%;
    }
    
    .efficacyandSafetyPageTemplate img.js-responsive-image.tab-2 {
        position: relative;
        left: 0px !important;
        width: 900px !important;
    }
    
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
     .panel_details-anchor {
          
            right: 660px;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
        #mat-tab-content-1-1{
            .panel_body-Image.col-xs-12.ng-star-inserted{
                position: relative;
        top: 0px;
        margin-bottom:45px;
            }
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
        #mat-tab-content-1-1{
            div#panel_details-anchor3{
                 position: absolute;
                // top: 118px;
                top: 20px;
        left: -27px;
            }
            }
        }
    .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
     .product_efficacy-box2{
        width: 1200px;
        position: relative;
        left: -37px;
     }
    }
    .efficacyandSafetyPageTemplate .product_common-box-content
        li{
            width:60%
        }
    
    .efficacyandSafetyPageTemplate {
        .product_common-box-Image1
    
     img.js-responsive-image1.box{
        left:30px;
    }
    }
    .efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
        .product_efficacy-header2 ,.product_efficacy-text, .product_efficacy-header3{
            position: relative;
            right: 25px !important;
        }
    }
    .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
       #mat-tab-content-1-1 .banner__component--responsive img{
            width: 100%;
            height: auto;
            margin-top: 7%;
            transform: scale(1.2);
            padding-left: 5% !important;
            position: relative;
            top:13px;
            margin-left: 2% !important;
        }
        .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
        #panel_details-studyText1{
            display:none;
        }
        }
        .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
           #mat-tab-content-1-0 #panel_details-studyText2{
                display:none;
            }
            }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first {
        #mat-tab-content-0-1 .panel_body-indicator2{
            position: relative;
            right: 50px;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        #mat-tab-content-0-1 .panel_details-button{
            position: relative;
        right: 23px;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        #mat-tab-content-0-1 .product_square-icon{
            position: relative;
        // top:18px ;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        #mat-tab-content-0-1 span.product_square-text{
            position: relative;
            left: 15px;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        #mat-tab-content-0-1 span.product_square-text{
            position: relative;
            span.product_square-text{
                bottom:19px;
            }
            // top: 35px !important;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        #mat-tab-content-0-1 span.product_square-text{
        bottom:19px;
    }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        .product_efficacy-header2{
            top:0px;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
    .clinical-flucelvax-second .product_efficacy-header3 {
        position: relative;
        bottom: -4px;
    }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
        .product_efficacy-header2 ,.product_efficacy-text, .product_efficacy-header3{
            position: relative;
            right: 25px !important;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
        .product_efficacy-header2{
            top: -65px !important;
        left: -46px !important;
    }
        }
        body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
            .product_efficacy-header3{
                bottom: 106px !important;
                left: -5px ;
        }
            }
            body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
                .product_efficacy-text{
                    position: relative !important;
                    top: -76px !important;
            }
                }
                body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
                    img.js-responsive-image{
                      left:76px !important;
                }
                    }
                    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
                    .panel_details-button{
                        position: relative;
                        top: 19px;
                    }
                }
            }
            body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
                #panel_details-studyText2{
                    width: 310px;
                    margin-left: initial;
                    margin-left: 491px;
                    position: relative;
                    top: 109px;
                }
                #panel_details-studyText1{
                    width: 174px;
                    margin-left: 313.5px;
                    position: relative;
                    top: 149px;
                }
            }
            .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
                #mat-tab-content-1-0 .pop.active{
                  
                    top:-487px !important;
                    position: relative;
                }
               #mat-tab-content-1-1 .panel_details-anchor{
                    position: absolute;
                    top: 0px;
                    left: 11px;
                    margin-top: 12% !important;
                    margin-left: 10%;
            }
            #mat-tab-content-1-1 .pop.active{
                top:352px !important;
            }
        }
        body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
          #mat-tab-content-1-0 img.js-responsive-image {
                /* position: relative; */
                left: 104px !important;
            }
            .banner__component--responsive img{
                width: 100%;
                height: auto;
                margin-top: 0%;
                transform: scale(1.2);
                padding-left: 2% !important;
                position: relative;
                bottom: 23px !important;
            }
            #mat-tab-content-1-1 #panel_details-studyText1{
                top: 82px;
                width: 202px;
        right: 84px;
            }
            #mat-tab-content-1-1 #panel_details-studyText2{
                width: 370px;
                right: 57px;
                top: 40px;
            }
            #mat-tab-content-1-1 .pop.active:before {
                top: 377px !important;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        #mat-tab-content-0-0 .pop.active{
            top: 452px !important;
            :before {
                content: "";
                position: absolute;
                top: -11px;
                right: 28px;
                background: url(/assets/images/Rectangle_arrow.png);
                width: 19px;
                height: 19px;
            }
        }
        #product_efficacy-tabContainer1 #mat-tab-content-0-1 .pop.active{
            top: 320px !important;
        }
        #product_efficacy-tabContainer1 #mat-tab-content-0-0 .panel_body-indicator1 {
            right: 0% !important;
    }
    
    }
    body.loggedin #mat-tab-content-0-1 .panel-body-studyDetails.col-xs-12 .panel_body-indicator2 {
        width: 45% !important;
        position: relative !important;
        top: -13px;
        right: 61px;
    }
    body.loggedin #mat-tab-content-0-1 .panel-body-studyDetails.col-xs-12 span.product_square-icon{
        position: relative;
        top: 14px;
        right: 15px;
    }
    body.loggedin #mat-tab-content-0-1 .panel-body-studyDetails.col-xs-12 .panel_body-indicator1 span.product_square-icon {
        position: relative;
        top: 0px;
        right: 15px;
    }
    .efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
        #mat-tab-content-1-1 .pop.active {
        top: 352px !important;
    }
    }
    .efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
        #mat-tab-content-1-0 .pop.active {
            top: -509px !important;
            position: relative;
    }
    .pop-body:before {
        content: "";
        position: absolute;
        top: 4px !important;
        left: 57px !important;
    }
}
.efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
    #mat-tab-content-1-0{
#panel_details-studyText2{
    display:none !important;
}
    }
}
.efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
    #mat-tab-content-1-0 img.js-responsive-image{
top:0px !important;
position:relative;
}
}
.efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
    #mat-tab-content-2-0 .pop.active {
        top: 239px !important;
        position: relative;
}
:before{
    top: 10px !important;
    left: 46px !important;
}
}
.efficacyandSafetyPageTemplate app-clinical-flucelvax-second {
    #mat-tab-content-2-0  {
        .product_efficacy-tabContainer2 .knowledgeHub_abandonedPopClose{
            background-color: #bfbfbf !important;
        }
    }
}
.efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
    #product_efficacy-tabContainer1 #mat-tab-content-0-1 .pop.active{
        top: 280px !important;
    }

    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        #product_efficacy-tabContainer1 #mat-tab-content-2-0 .pop.active{
            top: 421px !important;
        }
    }
    //     ::before{
    //         display:none !important;        }
    // }
 
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{
        #product_efficacy-tabContainer1 #mat-tab-content-0-1 .pop.active{
            top: 281px !important;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
        #product_efficacy-tabContainer2 #mat-tab-content-1-0 .pop.active{
            top: -459px !important;
        }
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second #mat-tab-content-1-1 .pop.active {
        top: 366px !important;
        padding:15px !important;
    }
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-first{

    .product_efficacy-box1  {
        position: relative;
        right: 4%;
    }
}
    body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
    .product_efficacy-header2 {
        position: relative !important;
        right: 8% !important;
        top:-50px !important;
    }
}
body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
.clinical-flucelvax-second .product_efficacy-header3 {
    position: relative;
    bottom: 81px;
}
}
body.loggedin .efficacyandSafetyPageTemplate app-clinical-flucelvax-second{
.clinical-flucelvax-second .product_efficacy-text {
    padding: 0 !important;
    position: relative;
    padding-bottom: 0 !important;
    right: 23px !important;
    bottom: 82px !important;
    margin-top: 42px !important;
}
div#product_efficacy-tabContainer2{
    margin-top:-67px !important;
}
}

.seqirusb2b-flu360 {
    .efficacyandSafetyPageTemplate {

        img.js-responsive-image1 {
            width: 131% !important;
            float: none !important;
            max-width: 762px !important;
            min-width: auto !important;
        }

        img.js-responsive-image {
            left: 0px !important;
            padding-left: 8% !important
        }

        .flucelvax-top-navigation .simple-banner.banner__component--responsive {
            .js-responsive-image {
                width: 100% !important;
                padding-left: 0% !important;
            }
        }

        app-clinical-flucelvax-first {
            .product_efficacy-box2 {
                margin-left: 0px !important;
                width: 107%;
            }
    
            div#product_efficacy-tabContainer1 {
                width: 100% !important;
            }
    
            img.js-responsive-image.graph {
                width: 100% !important;
            }
    
            .panel_body-indicator1, .panel_body-indicator2 {
                width: auto !important;
            }
        }

        app-clinical-flucelvax-second {
            #product_efficacy-tabContainer2 .panel_details-anchor {
                left: 0% !important;
            }

            .panel_body-Image {
                right: 0% !important
            }

            .product_efficacy-tabContainer .panel_body-Image {
                width: 84% !important;
            }

            div#product_efficacy-tabContainer2 {
                width: 104%;
            }
        }

    }

}

body.loggedin.seqirusb2b-flu360 .efficacyandSafetyPageTemplate {
    img.js-responsive-image1 {
        width: 131% !important;
    }

    app-clinical-flucelvax-second {
        .product_efficacy-header2{
            right: 5% !important;
        }

        #mat-tab-content-1-0 img.js-responsive-image {
            left: 3% !important;
        }
        #product_efficacy-tabContainer2 .panel_details-anchor {
            left: -3% !important;
        }
    }
    app-clinical-data-third {
        img.js-responsive-image {    
            left: 0px !important;
            width: 100% !important;
        }
    }

    app-clinical-flucelvax-first {
        .product_efficacy-text {
            padding-left: 5% !important;
        }
        .product_efficacy-header2 {
            padding-left: 4% !important;
        }
        .product_efficacy-box2 {
            margin-left: 2% !important;
            width: 100%;
        }

        div#product_efficacy-tabContainer1 {
            width: 100% !important;
            right: 0px !important;
        }

        img.js-responsive-image.graph {
            width: 100% !important;
        }

        .panel_body-indicator1, .panel_body-indicator2 {
            width: auto !important;
        }

        .product_efficacy-header {
            margin-left: 4% !important;
        }
    }
    
}

