.fluadRealWorldEvidencePageTemplate {
    
    .product_common-box{
        margin-top: 0 !important;
    }

    .panel_body-red {
        background: linear-gradient(22.3deg, #d5390a 14.62%, #872102 84.42%);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Campton-SemiBold';
    }

    .real-world-nav{
        display: block !important;
    }

    .product_image-textReverse .product_image-textSide-content ul {
        margin-left: 18px;
        padding-left: 15px;
    }

    .product_image-textReverse .product_image-textSide-content ul li {
        font-size: 20px;
        line-height: 28px;
        margin-top: 25px;
        color: #000;
        font-family: "Campton-Book";
    }

    #productMenu a:nth-child(4){
        display: none !important;
    }

    #productMenu .blue-background{
        padding: 10px 20px !important;
        margin-top: 10px !important;
        margin-bottom: 15px !important;
    }

    .blue-un span{
        width: 180px !important;
        display: block !important;
        border: 3px solid #4ECFF8 !important;
        position: relative !important;
        top: 18px !important;
        }

    .hidden-lg{
        display: none !important;
    }

    .product_fluad-rweHeader .product_regular{
        font-family: 'Campton-Book';
        font-size: 40px;
        vertical-align: top;
    }

    .product_semibold{
        font-family: 'CamptonSemiBold' !important;
    }

    .product_image-textHeader .product_semibold{
        sup{
            font-family: 'CamptonSemiBold' !important;
            font-size: 65% !important;
        }
    }

    .product_bold .product_regular{
        sup{
        font-family: 'Campton-Book' !important;
        font-size: 65% !important;
        }
    }

    

    @media (min-width: 1024px) {
        cx-page-layout .SideContent {
            float: left !important;
        }

        cx-page-layout .BodyContent{
            float: left !important;
        }

    }

    @media only screen and (max-width: 1023px) {
        .product_common-box {
            margin-top: 98px;
            padding-bottom: 66px !important;
            margin-bottom: 96px !important;
        }

        .product_common-box .product_common-box-container {
            width: 100% !important;
            flex-direction: column !important;
            padding: 0px 5px !important;
        }

        .product_common-box.fluad-commonbox .product_common-box-container {
            width: 100% !important;
        }

        .product_common-box.fluad-commonbox .product_common-box-content {
            width: 100% !important;
            padding-right: 0px !important;
        }

        .product_common-box .product_common-boxHeader {
            margin-top: 48px !important;
            font-size: 28px !important;
            line-height: 34px !important;
        }

        .product_common-box .product_common-box-content ul li {
            font-size: 18px !important;
            line-height: 24px !important;
        }

        .product_common-box .product_common-box-content ul li .product_common-innerUL {
            margin-top: 13px !important;
            margin-left: 0px !important;
        }

        a.product_box1-button {
            font-size: 16px !important;
            line-height: 16px !important;
            padding: 12px 20px 10px !important;
            margin-top: 24px !important;
        }

        .product_common-box .product_box1-button {
            margin-top: 30px !important;
            font-size: 16px !important;
            line-height: 16px !important;
            margin-bottom: 50px !important;
        }

        .product_common-box.fluad-commonbox .product_box1-button {
            margin-bottom: 35px !important;
        }

        .product_common-box .product_common-box-Image {
            width: 100% !important;
            margin-top: 0px !important;
            margin-bottom: 50px !important;
        }

        .product_common-box.fluad-commonbox .product_common-box-Image {
            width: 100% !important;
            margin-bottom: 32px !important;
            margin-top: 0px !important;
        }

        .product_image-textReverse .product_image-textSide-content ul li {
            font-size: 18px;
            line-height: 26px;
            margin-top: 20px;
        }
        .logOut{
            display: none !important;
        }

        .hidden-lg{
            display: block !important;
        }

        

    }

    @media only screen and (max-width: 991px) {
        .cx-media img {
            width: 86px;
            padding-right: 5px;
        }
    }

    .product_rwe-cards .product-rwe_text3 ul {
        margin-left: -8px;
        margin-bottom: 0px;
    }

    @media (max-width:600px) {
        .notMob{
            display: none !important;
        }
    }


    @media only screen and (max-width: 991px) {
    .header .SiteLogo cx-media img {
        width: 86px !important;
        padding-right: 5px !important;
    }

    .header .SiteLogo cx-media img[title="seqirus color logo"] {
        padding-top: 16% !important;
        padding-left: 8px !important;
    }

    .header .SiteLogo {
        padding: 20px !important;
    }
    }
    .bottom_footer{
        display: none;
    }
    .bottom_footer_grey{
        display: block !important;
        background: #F7F9F8 !important;
        p{
            color: #5d6f7b !important;
        }
    }
    .sticky_container .sbs__body{
        color: #4c4c4c !important;
    }
    #safetyInfo{
        background-color: white !important;
    }
    .gery-references{
        strong {
            font-family: "Campton-Bold", sans-serif;
        }
    }
    .product_common-boxHeader{
        sup{
            font-size: 65%;
        }
    }
    .product_image-textReverse.real-world-reverse .product_efficacy-header {
        background: linear-gradient(120.82deg, #00c2ff 0.95%, #3f961d 100.04%) !important;
    }
    #rwe-tab2primary .product-hideShow_card {
        text-align: right !important;
    }
    // .product_secondary-navigation a:nth-child(1) span {
    //     position: absolute;
    //     bottom: -22px;
    //     left: 0;
    //     border: 3px solid #4ECFF8 !important;
    //     width: 68px;
    // }

    // .product_secondary-navigation a:first-child.active span,
    // .product_secondary-navigation a:first-child:hover span {
    //     position: absolute;
    //     border: 3px solid #4ECFF8;
    //     bottom: -22px;
    //     width: 68px;
    //     left: 0;
    // }

    .product_secondary-navigation a:nth-child(2).active span,
    .product_secondary-navigation a:nth-child(2):hover span{
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        width: 70%;
        left: 0;
    }
    .product_secondary-navigation a:nth-child(1):hover span,
    .product_secondary-navigation a:nth-child(1).active span,
    .product_secondary-navigation a:nth-child(2):hover span
    .product_secondary-navigation a:nth-child(2).active span {  
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        width: 40%;
        left: 0;
    }
    .product_secondary-navigation .blue-un2:hover span,
    .product_secondary-navigation .blue-un2:active span{
        position: absolute;
        border: 3px solid #4ECFF8;
        bottom: -22px;
        width: 59%;
        left: 0;
    }
}
body.loggedin .fluadRealWorldEvidencePageTemplate{
    .product_secondary-navigation a:nth-child(1):hover span,
    .product_secondary-navigation a:nth-child(1):active span
    {
        width: 60%;
    }
    .product_secondary-navigation a:nth-child(2):hover span,
    .product_secondary-navigation a:nth-child(2):active span
    {
       width: 85%;
    }
    .product_secondary-navigation a:nth-child(4):hover span,
    .product_secondary-navigation a:nth-child(4):active span
    {
       width: 85%;
    }
    .product_secondary-navigation .blue-un2:hover span,
    .product_secondary-navigation .blue-un2:active span{
        width: 74%;
    }
}