.cellBasedSolutionPageTemplate{
    .flucelvax-top-navigation{
        display: none;
    }
    a.product_download-button{
        display:none;
    }
    .breadcrumbs--page-nav {
        position: relative;
        left: 1px;
        height: 47px;
        margin-bottom: 0;
        padding-right: 181px !important;
}
.product_box1-disclaimer.disclaimer--paragraph{
    font-family: 'Campton-Book' !important;
}
.adjuvantbasedvideo .watchsubtext{
    font-family: "Campton-Book";
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #4c4c4c;
    padding-bottom: 20px;
    width: 487px;
}
.product_common-boxHeader{
    width:70%;
}
.product_common-box-content li{
    width:70%;
}
.product_box1-disclaimer.disclaimer--paragraph{
    font-family: 'Campton-Book';
}
.cell-based-video{
    display: block !important;
}
}
.cellBasedSolutionPageTemplate app-fluadchoosenow{
.product_common-box-Image1 img.js-responsive-image1.box{
    min-width: 509px !important;
}
}
body.loggedin .cellBasedSolutionPageTemplate{
    .col-md-12.breadcrumbs--page-nav.hidden-xs.hidden-sm{
        display:none;
    }
}
body.loggedin .cellBasedSolutionPageTemplate {

.adjuvantbasedvideo .watchtext{
    width:90%;
}
.adjuvantbasedvideo .watchsubtext{
    width: 412px;
}

}
body.loggedin .cellBasedSolutionPageTemplate app-third-image{
    video{
        width: 82%;
        position: relative;
        left: 43px;
    }
}

.seqirusb2b-flu360 .cellBasedSolutionPageTemplate app-fluadchoosenow{
    .product_common-box-Image1 img.js-responsive-image1.box{
        width: 131%;
        max-width: 776px !important;
        float: none !important;
    }
}