
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$flu360_csf-primary: mat.define-palette(mat.$indigo-palette);
$flu360_csf-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$flu360_csf-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$flu360_csf-theme: mat.define-light-theme((
  color: (
    primary: $flu360_csf-primary,
    accent: $flu360_csf-accent,
    warn: $flu360_csf-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($flu360_csf-theme);

/* You can add global styles to this file, and also import other style files */

@import 'styles-config';
@import '@spartacus/styles/index';
@import 'assets/css/Common_style';
@import 'assets/css/create-profile_style.scss';
@import 'assets/css/sitelogo_style.scss';
@import 'assets/css/gigya_screens_styles.scss';
@import 'assets/css/start-order_style.scss';
@import 'assets/css/colorVariable.scss';
@import 'assets/css/FontVariable.scss';
@import 'assets/css/UK_Start_order.scss';
@import 'assets/css/OrderSummaryUk.scss';
@import 'assets/css/Us-allproducts.scss';
@import 'assets/css/US-afluria.scss';
@import 'assets/css/operational-efficiency -us.scss';
@import 'assets/css/us-helpandfaq.scss';
@import 'assets/css/us-clinical-fluad.scss';
@import 'assets/css/flucel-vax.scss';
@import 'assets/css/clinical-support.scss';
@import 'assets/css/us-fluad-rwe.scss';
@import 'assets/css/order_detail_calendar.scss';
@import 'assets/css/uk-overview.scss';
@import 'assets/css/uk-public-page.scss';
@import 'assets/css/US_Financial_guidance.scss';
@import 'assets/css/uk-myProfile.scss';
@import 'assets/css/error-page.scss';
@import 'assets/css/uk-contactus.scss';
@import 'assets/css/US-fluad.scss';
@import 'assets/css/aqiv-uk.scss';
@import 'assets/css/rwe.flucelvax.scss';
@import 'assets/css/cookiepolicy.scss';
@import 'assets/css/influenza.scss';
@import 'assets/css/influenza-65plus.scss';
@import 'assets/css/Qivc-uk.scss';
@import 'assets/css/coding-and-billing.scss';
@import 'assets/css/toolsdetailedpage.scss';
@import 'assets/css/us-overview.scss';
@import 'assets/css/cell-based-solution.scss';
@import 'assets/css/registrationUk.scss';
@import 'assets/css/uk-about.scss';
@import 'assets/css/us-landing-resource.scss';
@import 'assets/css/uk-homepage.scss';
@import 'assets/css/clinical-data-flucelvax.scss';
@import 'assets/css/adjuvant-advantage.scss';
@import 'assets/css/us-about.scss';
@import 'assets/css/dashboard.scss';
@import 'assets/css/emailverification.scss';
@import 'assets/css/amend-order-uk.scss';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
