.SeqirusAboutPageUKFirst-Slot {

    .rectangle-down {
        top: 100px !important;
        right: 32px !important;
    }

    app-about-section1 {

      .about-us {
        width: 121% !important;
      }
      .hero--overview-content {
        @media (min-width: 1024px){
          padding-left: 3%;
        }
  
        @media (max-width: 1300px){
          width: 40% !important;
        }
  
        @media (min-width: 1300px){
          width: 47% !important;
        }
      }
  
      .hero--overview {
        @media (min-width: 1024px){
          height: 650px !important;
        }
  
        @media (min-width: 1300px){
          height: 596px !important;
        }
      }
    }
}

.SeqirusAboutPageUKThird-Slot {
  // app-about-vaccine {
  //   .carousel-inner {
  //     p.carousel-content {
  //       padding-top: 21% !important;
  //     }
  //   }
  // }

  .active-bar {
    bottom: -5px !important
  }
  .carousel-bar-line {
    top: 255px !important;
  }

  .influenza-carousel-desktop-btn-container {
    @media (min-width: 1024px) and (max-width: 1150px ){
      margin-top: 24% !important;
    }
    @media (min-width: 1150px) and (max-width: 1250px ){
      margin-top: 22% !important;
    }
    @media (min-width: 1250px) and (max-width: 1400px ){
      margin-top: 20% !important;
    }
    @media (min-width: 1400px){
      margin-top: 16% !important;
    }

    @media (min-width: 1600px){
      margin-top: 12% !important;
    }
  }
}

.SeqirusAboutPageUKFourth-Slot {
  .large--carousel-btn-container {
    margin-top: 40px;
  }

  .carousel-inner .left-column p {
    width: 33%;
    font-size: 18px;
    line-height: 27px;
  }

  .page-references .container {
    margin-left: 38px;
  }

  .referencesContainerHomeUK {
    color: #5d6f7b;
  }

  app-first-comp {
    img.d-block.w-100 {
       @media (min-width: 1024px) and (max-width: 1920px){
        margin-top: -160px !important;
       }
    }

    // .downloadLink {
    //   display: block;
    // }
  }
}

.SeqirusAboutPageUKFifth-Slot {
  .page-references .container {
    margin-left: 38px;
  }
}

.SeqirusAboutPageUKSixth-Slot {
  .referencesContainerHomeUK {
    color: #5d6f7b !important;
  }
}

@media (min-width: 1025px) {
  .SeqirusAboutPageUKFirst-Slot {
    .hero--overview {
      margin-top: -3% !important;
      height: 600px;
    }

    /* .hero--overview-content h1.content {
        margin-top: 130px !important;
        margin-left: 20px !important;
    } */
  }
}


.seqirusb2b-uk .AboutSeqirusPageTemplate {
  app-first-comp {
    a {
      margin-left: 4%;
    }

    .right-column {
      margin-top: -69px;
    }

    .left-column {
      z-index: 99;
      top: 20px;
    }
  }
  
  app-about-section1 {
    .hero--overview-content {
      h1.content {
        margin-left: -40px !important;
        margin-top: 110px !important;
      }

      .ready-ref {
        padding-left: 15px;
      }

      .about-us {
        margin-bottom: 0px !important;
      }
    }

    .hero--overview {
      .button--hero-solid-red {
        margin-top: 30px !important;
      }

      @media (min-width: 1024px) {
        position:relative;
        background-attachment: local;
        top: 0px;
      }
    }
  }

  app-about-vaccine {
    .carousel-inner {
      .carousel-item {
        .order_class .carousel-content{
          margin-left: 14%;
          width: 72%;
        }
      }
    }

    .link-container {
      a :hover {
        text-decoration: underline !important;
      }
    }
  }
}
.seqirusb2b-uk {
.influenza-carousel-desktop-btn-container{
  padding-top: 10% !important;
}
#influenza-carousel-desktop{
  .row-flex{
    height: 662px !important;
  }
}
#custom-Carousel{
  .large--carousel-btn-container{
    margin-bottom: 0px !important;
  }
}
.slide--content {
  padding-bottom: 73px !important; 
}
.page-references{
  margin-top: 8% !important;
}
.downloadLink{
  display: none;
}
}