.ReturnsPageTemplate,
.ReturnsNewPageTemplate {
    .materials_List_Text {
        ul {
            padding-left: 65px !important;
        }
    }

    .registerButton {
        font-family: 'Campton-Medium';
    }

    .picture_Resource_Text {
        .textBold {
            font-family: 'Campton-Medium';
            font-weight: bold;
        }
    }

    .returns_thq_video_container {
        .thq_Video_Headline {
            .coming-soon {
                color: #EA181B;
                text-align: center;
                font-family: 'Campton-Medium';
                // font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                padding: 0 5px;
                // width: 53% !important;
            }
        }
    }

    .materials_text_row {
        .materials_Headline {
            b {
                color: #2A3237;
                font-family: 'Campton-Medium';
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
            }
        }

        .materials_List_Text {
            ul {
                color: #2A3237;
                font-family: 'Campton-Book';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin-top: 30px;
                letter-spacing: 0;
            }
        }
    }
    .returnsSubText{
        position: relative;
        top: 10px;
    }
    .reg-material{
        padding-left: 2px;
    }
    .returns_Top_Job_Bag_Code{
        margin: 0px 25px 10px 40px !important;
    }
    .email_shortly_text a{ 
        color: blue !important;
        text-decoration: underline !important;
    }
}
.ReturnsPageTemplate{
    .reg-material{
        padding-left: 5px;
    }
    .Returnthank_box{
        margin: 0px 25px 11px 40px;
    }
}
.ReturnsNewPageTemplate {
    .referencesContainerHomeUK {
        padding: 2% 52px 0% 2%;
        margin-bottom: 0 !important;
        // margin-top: 20px;
    }

    // .returns_thq_video_container {
    //     .thq_Video_Headline {
    //         .coming-soon {
    //             width: 51% !important;
    //         }
    //     }
    // }
    .PibannerUk{
        padding: 10px 40px !important;
    }
}