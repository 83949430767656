.FlucelvaxPageTemplate, .efficacyandSafetyPageTemplate{
    .product_common-box {
        margin-top: 180px !important;
        padding-bottom: 21px;
        background-image: linear-gradient(22.33deg, #095938 13.49%, #1d8912 83.42%) !important;
        margin-bottom: 80px;
    } .container {
        padding: 0% 3%;
    }

    .fluad {
        display: none !important;
    }

    .fluad-firstvideo {
        display: none !important;
    }

    .fluad_imageside {
        display: none !important;
    }

    .fluad-firstcomponent {
        display: none !important;
    }
     
    .product_common-box .container {
        display: flex;
        justify-content: center;
    }
    .product_common-box .product_common-box-container {
        width: 82%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .product_common-box .product_common-box-content {
        width: 43%;
    }
    .product_common-box .product_common-boxHeader {
        color: #FFFFFF;
        font-family: 'Campton-SemiBold';
        font-size: 36px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 42px;
        margin-top: 61px;
        padding-right: 30%;
    }
    .product_common-box .product_common-box-content ul {
        margin-top: 30px;
        margin-left: 11px;
    }
     
    // ul, ol {
    //     padding-left: 15px;
    // }
    .product_common-box .product_box1-button {
        margin-top: 40px;
        font-family: 'CamptonSemiBold' !important;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        padding: 17px 20px 13px;
     
        border: 1px solid #ffffff;
        background-color: transparent;
        margin-bottom: 69px;
    }
    .product_common-box .product_common-box-Image {
        width: 56%;
        margin-top: -83px;
    }
    .product_common-box-Image img {
        width: 100% !important;
        transform: scale(1.2);
        max-width: none !important;
        padding-top: 8%;
    }
     
    img {
        vertical-align: middle;
    }
    img {
        border: 0;
    }
    .product_common-box .product_box1-disclaimer {
        text-align: left;
        margin-top: 0px;
        color: #ffffff;
    }
    .product_box1-disclaimer {
     
        width: 100%;
        font-family: 'Campton-Book';
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
    }
     
    .product_common-box .product_common-box-content .product_common-innerUL {
        margin-top: 13px !important;
    }
     
    .product_common-box .product_common-box-content ul {
       
        margin-left: 11px !important;
    }
    }
    //navigation-component
    .FlucelvaxPageTemplate a.blue-background,
    .efficacyandSafetyPageTemplate a.blue-background {
        margin-right: 0px;
        font-family: 'CamptonSemiBold' !important;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        background-color:#FF9E1B !important;
        padding: 10px 20px;
        text-transform: uppercase;
        max-width: 172px;
    }
    .FlucelvaxPageTemplate .product_secondary-navigation a:nth-child(1) span
{
    // .efficacyandSafetyPageTemplate .product_secondary-navigation a:nth-child(1) span {
        position: absolute;
        bottom: -22px;
        left: 0;
        border: 3px solid #FF9E1B !important;
        width: 116px;
    }
    .FlucelvaxPageTemplate .product_secondary-navigation a:nth-child(1) span:hover{
        width:116px !important;
    }
    .FlucelvaxPageTemplate .product_secondary-navigation a:first-child.active span,
    .FlucelvaxPageTemplate .product_secondary-navigation a:first-child:hover span,
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:first-child.active span,
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:first-child:hover span {
        position: absolute;
        border: 3px solid #FF9E1B !important;
        bottom: -22px;
        width: 50%;
        left: 0;
    }
     
    .FlucelvaxPageTemplate .product_secondary-navigation a:nth-child(2).active span,
    .FlucelvaxPageTemplate .product_secondary-navigation a:nth-child(4).active span,
    .FlucelvaxPageTemplate .product_secondary-navigation a:nth-child(2):hover span,
    .FlucelvaxPageTemplate .product_secondary-navigation a:nth-child(4):hover span,
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:nth-child(2).active span,
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:nth-child(4).active span,
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:nth-child(2):hover span,
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:nth-child(4):hover span {
        position: absolute;
        border: 3px solid #FF9E1B !important;
        bottom: -22px;
        width: 70%;
        left: 0;
    }
     
    .FlucelvaxPageTemplate .product_secondary-navigation a:nth-child(3).active span,
    .FlucelvaxPageTemplate .product_secondary-navigation a:nth-child(3):hover span,
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:nth-child(3).active span,
    .efficacyandSafetyPageTemplate .product_secondary-navigation a:nth-child(3):hover span {
        // width: 116px;
        position: absolute;
        border: 3px solid #FF9E1B !important;
        bottom: -22px;
        width: 70%;
        left: 0;
    }
    .FlucelvaxPageTemplate #productMenu .child-item:nth-child(3),
    .efficacyandSafetyPageTemplate #productMenu .child-item:nth-child(3) {
        display: none;
    }

    //logged-in
     body.loggedin .FlucelvaxPageTemplate .product_image-textcontainer{
 width:80% !important;
    }
    body.loggedin .FlucelvaxPageTemplate .product_box1-content
    {
        padding:50px!important;
    }
    body.loggedin .FlucelvaxPageTemplate .product_box1-container{
        margin-left: 1% !important;
        margin-top: 8%;
    }
    body.loggedin .FlucelvaxPageTemplate .product_box1-text{
        padding: 3% !important;
        padding-top: 1% !important;
    }
    body.loggedin .FlucelvaxPageTemplate .product_box1-text2-gray{
        padding:25px 20px 29px !important;
        width: 90% !important;
        position: relative;
        left:5%;
    }
    
    body.loggedin .FlucelvaxPageTemplate .product_box1-disclaimer{
        margin-right:1% !important;
    }
    body.loggedin .FlucelvaxPageTemplate .product_image-textSide-content{
        width:70% !important;
    }
    body.loggedin .FlucelvaxPageTemplate .product_image-textSide.flucelvax_imageside{
        margin-top:0px !important;
    }
    body.loggedin .FlucelvaxPageTemplate app-flucel-fifth{
        height:600px !important;
        position: relative;
        right: 5% !important;
    }
    /* body.loggedin .FlucelvaxPageTemplate .product_secondary-navigation a,
.product_secondary-navigation a:focus,
.product_secondary-navigation a.active,
.product_secondary-navigation a:hover {
    color: #ffffff !important;
    font-family: 'Campton-Medium' !important;
    font-size: 16px !important;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20.95px;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    flex-grow: 1;
    align-self: center;
} */
.FlucelvaxPageTemplate .bottom_footer{
   padding: 0% 25px 1px !important;
   background:#F7F9F8 !important;
}
body.loggedin .FlucelvaxPageTemplate img.js-responsive-image{
    width: 83% !important;
    left: -25%;
    bottom:179px !important;
    left:-120px !important;
}
body.loggedin .FlucelvaxPageTemplate img.js-responsive-image1 {
    width: 1100px !important;
    position: relative;
    position: relative;
    top: 54px;

        // left: 11%;
}
body.loggedin .FlucelvaxPageTemplate  .simple-banner.banner__component--responsive{
    position: relative;
    bottom: 70px !important;
}
body.loggedin .FlucelvaxPageTemplate .product_image-textcontainer{
    bottom:65px !important;
}
body.loggedin .FlucelvaxPageTemplate .product_box1-disclaimer.hidden-xs.hidden-sm{
    position: relative;
    bottom: 70px;
    left: 110px;
}
body.loggedin .FlucelvaxPageTemplate .bottom_footer{
    width: 1060px !important;
    position: relative;
    // padding: 97px !important;
    // padding: 20px 100px 30px 40px !important;
}
body.loggedin .FlucelvaxPageTemplate .bottom_footer p{
    padding: 4px !important;
    padding-right: 0px !important;
}
.FlucelvaxPageTemplate .bottom_footer{
    width: 1060px;
    position: relative;

}
.FlucelvaxPageTemplate .bottom_footer {
    width: 1270px;
    position: relative;
    right: 3%;
}
.FlucelvaxPageTemplate .product_box1-disclaimer.hidden-xs.hidden-sm{
    position: relative;
    bottom: 90px;
    left: 110px;
}
.FlucelvaxPageTemplate .product_common-box-content li{
    width:70%;
}
body.loggedin .FlucelvaxPageTemplate li{
    width:70% !important;
}
.FlucelvaxPageTemplate .product_box1-disclaimer.disclaimer--paragraph{
    font-family: 'Campton-Book' !important;

}
body.loggedin .FlucelvaxPageTemplate .product_box1-disclaimer.disclaimer--paragraph{
    font-family: 'Campton-Book' !important;

}
.FlucelvaxPageTemplate .simple-banner.banner__component--responsive{
    width:550px !important;
}
.FlucelvaxPageTemplate img.js-responsive-image1{
    width: 1300px !important;
}
.FlucelvaxPageTemplate img.js-responsive-image1.box{
    max-width: 550px !important;
    position: relative;
    right: 20%;
    
}
.FlucelvaxPageTemplate img.js-responsive-image.young-girl{
    width: 490px !important;
    position: relative;
    bottom: 100px !important;
    left: -100px !important;
}
.FlucelvaxPageTemplate app-flucel-fifth{
    height: 620px;
}
.FlucelvaxPageTemplate strong.strong-tag{
    display:none !important;
}
 body.loggedin .FlucelvaxPageTemplate strong.strong-tag{
    display:none !important;
}
body.loggedin .FlucelvaxPageTemplate .product_image-textcontainer{
    position: relative;
    left:0% !important;
}

.FlucelvaxPageTemplate section#safetyInfo{
    background-color: #fff !important;
}

body.loggedin .FlucelvaxPageTemplate .product_box1-content{
    height:250px !important;
}
body.loggedin .FlucelvaxPageTemplate .product-content{
    position:relative;
    bottom:35%;
}
body.loggedin .FlucelvaxPageTemplate a.product_box2-button{
    position:relative;
    bottom:48% !important;
}
.FlucelvaxPageTemplate .sbs__body .strong{
    color: #4C4C4C !important;
    font-weight: bold !important;
    font-family: "Campton-Bold", sans-serif !important;
}
body.loggedin .FlucelvaxPageTemplate .sbs__body .strong{
    color: #4C4C4C !important;
    font-weight: bold !important;
    font-family: "Campton-Bold", sans-serif !important;
}
.FlucelvaxPageTemplate p.references{
    display: none !important;
}
body.loggedin .FlucelvaxPageTemplate p.references{
    display: none !important;
}
.FlucelvaxPageTemplate .bottom_footer p{
    padding:10px !important;
    position: relative !important;
    right: 0px !important;
}

.seqirusb2b-flu360 .FlucelvaxPageTemplate {
    .simple-banner.banner__component--responsive{
        width:100% !important;
    }
    img.js-responsive-image1 {
        width: 131% !important;
        float: none !important;
        max-width: 1360px !important;
    }

    app-flucelvax-banner {
        .flucelvax_image-center {
            .container {
                padding: 0% !important;
            }
        }

        .product_image-textcontainer {
            width: 100% !important;
            
            .product_image-text1 {
                padding: 0px 5% !important;
                width: 100% !important;
            }
        }
    }

    app-third-navbar {
        .product_box1-content {
            .product-content {
                padding: 0px 2% 0px 4% !important;
            }
        }

        .product_box1-container {
            width: 88% !important;
        }
    }

    app-flucel-fifth {

        .product_image-textSide {
            margin-right: 0% !important;
        }
        .product_image-textSide-container {
            width: 100% !important;

            .product_image-textSide-content {
                width: 50% !important;
            }

            .product_image-textSide-Image {
                width: 50% !important;

                .js-responsive-image.young-girl{
                    margin: 0px !important;
                    top: 0px !important;
                    left: 0px !important;
                }
            }
        }
    }
}

body.loggedin.seqirusb2b-flu360 {
    .FlucelvaxPageTemplate {
        img.js-responsive-image1 {
            width: 131% !important;
        }
        .bottom_footer{
            width: 106% !important;
            line-break: anywhere;
        }
    
        img.js-responsive-image1 {
            width: 131% !important;
            float: none !important;
            max-width: 1360px !important;
        }
    
        .product_image-textcontainer {
            width: 98% !important;
        }
    
        app-third-navbar {
            .product_box1 {
                .product_box1-container {
                    width: 87%;
                    padding-left: 3% !important;
                }
                .product_box1-text {
                    padding-left: 6% !important;
                }
            }
        }
    
        app-topnavigation {
            .product_secondary-navigation a:nth-child(1) span {
                width: 105px !important;
            }
    
            .product_secondary-navigation a:nth-child(2):hover span {
                width: 212px !important;
            }
    
            .product_secondary-navigation a:nth-child(4):hover span {
                width: 180px !important;
            }
        }

        app-flucel-fifth {
            .product_image-textSide-Image .js-responsive-image.young-girl {
                width: 100% !important;
                top: 65px !important;
            }
        }
    }

    .cellBasedSolutionPageTemplate {
        app-topnavigation { 
            .product_secondary-navigation a:nth-child(4):hover span {
                width: 180px !important;
            }
        }
    }

    .efficacyandSafetyPageTemplate {
        app-topnavigation {
            .product_secondary-navigation a:nth-child(1):hover span {
                width: 105px !important;
            }
    
            .product_secondary-navigation a:nth-child(2):hover span {
                width: 212px !important;
            }

            .product_secondary-navigation a:nth-child(3):hover span {
                width: 116px !important;
            }
    
            .product_secondary-navigation a:nth-child(4):hover span {
                width: 180px !important;
            }
        }
    }

    .RealWorldEvidencePageTemplate {
        app-topnavigation {
            .product_secondary-navigation a:nth-child(1):hover span {
                width: 105px !important;
            }
    
            .product_secondary-navigation a:nth-child(2):hover span {
                width: 212px !important;
            }

            .product_secondary-navigation a:nth-child(3):hover span {
                width: 116px !important;
            }
    
            .product_secondary-navigation a:nth-child(4) span, .product_secondary-navigation a:nth-child(4):hover span {
                width: 180px !important;
            }
        }
    }
}