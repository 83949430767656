.FluadPageTemplate img.hero--circlechart-chart {
    width: 250px !important;

}

.FluadPageTemplate .logo--card.center-xs {
    background-color: white !important;
    // margin-left: 400px !important;
}

.FluadPageTemplate a.no-underline.text-dark-gray.cta.f-sb {
    margin-top: 35px;
}

.FluadPageTemplate img.hero--circlechart-chart {
    display: none;
}

.FluadPageTemplate .afluria a.no-underline.text-dark-gray.cta.f-sb {
    display: none;
}

// .FluadPageTemplate div#nav-content-1 {
//     max-height: 800px;
// }

.AboutSeqirusPageTemplate .breadcrumbs--page-nav {
    position: relative;
    right: 10px !important;
}

// .FluadPageTemplate #nav-content-2 {
//     min-width: 657px !important;

// }

.FluadPageTemplate .aqiv-second-block {
    font-family: 'CamptonSemiBold';
    margin-top: 20px;
}

.FluadPageTemplate .aqiv-third-italic {
    font-size: 14px !important;
    font-style: italic;
}

.FluadPageTemplate #nav-content-3 {
    min-width: 690px;
}

.FluadPageTemplate #nav-content-1 {
    min-width: 670px;
}

.FluadPageTemplate #nav-content-3 img.resource-image {
    position: relative;
    bottom: 25%;
    max-width: 99px !important;
}

.FluadPageTemplate #nav-content-3 .row {
    position: relative;
    top: 20px;
}

.FluadPageTemplate p.reference {
    font-size: 14px !important;
    letter-spacing: 0 !important;
    line-height: 20px !important;
    color: #5d6f7b;
    // width: 90% !important;
    margin-left: auto;
    margin-right: auto;
}

.AboutSeqirusPageTemplate p.text-center.top-text {
    width: 86%;
    font-size: 18px;
}

.AboutSeqirusPageTemplate img.d-block.w-100 {
    max-width: 35% !important;
    margin-top: -14% !important;
    margin-left: 41% !important;
    max-width: 660px !important;

}

.AboutSeqirusPageTemplate .d-flex .carousel-inner .left-column p {
    letter-spacing: 0;
    width: 30% !important;
}

.AboutSeqirusPageTemplate p.about-us {
    padding: 15px !important;
    position: relative !important;
    right: 35px !important;
    width: 123% !important;
}

.AboutSeqirusPageTemplate .carousel .slide .desktop-carousel.carousel-inner {
    margin-inline-start: 0px !important;
}

.AboutSeqirusPageTemplate h2.head-line {
    font-family: "Campton-Medium", sans-serif;
    font-size: 40px;
    line-height: 51px;
    color: #2a3237;
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: 500;
    width: 40%;
}

.AboutSeqirusPageTemplate .carousel-item1.active {
    padding: 0px !important;
}

.AboutSeqirusPageTemplate .carousel-inner {
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
}

body.loggedin:has(app-custom-aqiv-third) .FluadPageTemplate {
    .content_para ul {
        padding-left: 15px !important;
        margin-bottom: 40px;
    }
    .vaccine-portfolio-row .col-xl-12{
       display: none;
    }
    .afluria .col-xl-12{
        display: none;
    }
    .referencesContainerHomeUK{
        padding-bottom: 70px !important;
        padding-left: 52px !important;
        margin-bottom: 0 !important;
        margin-left: 0 !important;
    }
    @media (min-width: 1024px) {
       .page-references {
       .container {
            width: 100% !important;
            max-width: 100%;
            padding-left: 52px;
            padding-right: 25px;
        }
        .reference{
         margin-left: 0 !important;
         margin-right: 0 !important;
        }
    }
    }
    @media (min-width: 1024px) {
        .page-references .reference {
            line-break: anywhere;
        }
    }
    .containerUK{
        padding: 0 !important;
        strong{
            font-family: "Campton-Bold", sans-serif;
        }
    }
    .vaccine-portfolio-row{
        display: flex;
    justify-content: center;
    .center-content{
        padding-bottom: 40px;
    }
    }
    .first-row,.second-row{
        flex-direction: row-reverse;
    }
    .second-row .col-md-7{
        margin-top: -16%;
    }
    .first-row .resource-image{
        margin-top: 22% !important;
    }
    .qivctextPageContent1{
        display: none;
    }
    .qivc-logo{
        display: none;
    }
    .content_para li{
        margin-bottom: 10px;
    }
    .prod-tabs .container{
        padding-left: 52px !important;
        padding-right: 52px !important;
    }
    .prod-sb__body .prod-sb__rightUK{
        padding-left: 15px;
    }
    .global_dashheader{
        font-size: 24px;
    }
    .logo--card a:hover{
       text-decoration: underline !important;
    }
}

.FluadPageTemplate div#nav-content-3 {
    .row .col-md-8{
    p.aqiv-second{
width:460px !important;
    }
}
}