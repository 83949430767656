//clinincal-support
.ClinicalSupportNewPageTemplate {
  .hero--circlechart-content .global_greybtn {
    background-color: #ea181b;
    border: 1px solid #ea181b;
  }
 
  .home_desktopresourcessection {
    margin-bottom: 4em;
  }
 
  #home_resourcessection {
    .global_greybtn {
      padding: 17px 29px 17px 29px !important;
      border: 1px solid #5D6F7B !important;
    }
    .global_line{
      height: 2px !important;
    }
  }
 
  @media (min-width: 1024px) {
    #join360-footer #join360-row .join360--hero-container .join360--hero-container--content {
      padding-top: 50px !important;
    }
  }
  #home_clinicallink .global_blackarrow{
    width: 24px;
    height: 26px;
    top: 50%;
    right: 40px !important;
    position: absolute;
    transform: translateY(-50%);
    z-index: 3;
    margin: 0 !important;
  }
}

.ClinicalSupportNewPageTemplate p.text--red-100.whatif--card-header {
  color: #3c9a90;
}

.ClinicalSupportNewPageTemplate p.text--red-100.callout {
  color: #3c9a90;
  margin-top: 30px !important;
}

.ClinicalSupportNewPageTemplate p.text--teal-100.whatif--teal-card-header {
  color: #5d6f7b;
}

.ClinicalSupportNewPageTemplate p.text--teal-100.calloutteal {
  color: #5d6f7b;
  margin-top: 30px !important;
}

.ClinicalSupportNewPageTemplate .breadcrumbs-opeff {
  display: none;
}

.ClinicalSupportNewPageTemplate .clinical-breadcrumbs .breadcrumbs--page-nav {
  display: block !important;
}

.ClinicalSupportNewPageTemplate .whatif--card-wide .whatif--card-content {
  padding: 40px 30px 30px 40px !important;
}

// .ClinicalSupportNewPageTemplate p.text--grey-110 {
//   width: 530px !important;

// }

.ClinicalSupportNewPageTemplate .join360-clinical-container .join360--hero-container .cells-360-bg {
  position: relative;
}

.ClinicalSupportNewPageTemplate .breadcrumbs--page-nav.d-none {
  margin-top: 2% !important;
}

.ClinicalSupportNewPageTemplate .whatif--card {
  width: 400px;
  // margin-left: -20% !important;

}

.ClinicalSupportNewPageTemplate .home_resourcesubheader {
  color: #2A3237 !important;
  font-family: campton-medium !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  letter-spacing: 0;
  margin-top: 10px !important;
  text-align: left !important;
}

.ClinicalSupportNewPageTemplate .home_resourcetext {
  color: #5D6F7B;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 0 !important;
  margin-top: 10px !important;
  margin-bottom: 85px !important;
  text-align: left;
}

.ClinicalSupportNewPageTemplate .whatif--card-content {
  margin-left: 40px;
}

@media (min-width: 1024px) {
  .join360-clinical-container .join360--hero-container .cells-360-bg {
    top: -178px;
    left: 50%;
    width: 627px;
    display: block;
  }
}

//-breadcrums end
.Influenza65PlusNewPageTemplate .text--grey-110 {
  width: 450px;
}

@media (min-width: 1025px) {
  .ClinicalSupportNewPageTemplate .whatif--card-wide {
    width: 637px !important;
    height: 259px !important;
  }
}


// multiple-needs clinical-support
.ClinicalSupportNewPageTemplate .container.section-spacing {
  // max-width: 1350px !important;
  width: 100%;
  max-width: 100%;
  #what-if-row {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1025px) {
  .ClinicalSupportNewPageTemplate #what-if-row {
    margin-bottom: 40px;
  }
}

#what-if-row {
  margin-bottom: 30px;
}

.ClinicalSupportNewPageTemplate #what-if-row {
  display: flex;
  justify-content: center;
}

.ClinicalSupportNewPageTemplate .whatif--card-wide {
  background-color: #f9fafa;
  width: 400px !important;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.ClinicalSupportNewPageTemplate .whatif--card-wide .whatif--card-content {
  padding: 40px 30px 30px 40px !important;
}

@media (min-width: 1025px) {
  .ClinicalSupportNewPageTemplate.whatif--card-wide .whatif--card-content .header {
    font-size: 24px;
    line-height: 31px;
  }
}

.ClinicalSupportNewPageTemplate .whatif--card-content .header {
  font-family: "Campton-Medium", sans-serif;
  // font-size: 20px;
  // line-height: 26px;
  min-height: 30px;
}

.ClinicalSupportNewPageTemplate.whatif--card-wide .whatif--card-content p {
  font-family: "Campton-Book", sans-serif;
}

.ClinicalSupportNewPageTemplate .header {
  background-color: #F9FAFA !important;
  // min-height: 64px;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}

.ClinicalSupportNewPageTemplate .text--teal-110 {
  color: #3c9a90 !important;
}

@media (min-width: 1024px) {
  .ClinicalSupportNewPageTemplate .whatif--card-wide .whatif--card-content .content {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1025px) {
  .ClinicalSupportNewPageTemplate .whatif--card-wide .whatif--card-content .callout {
    font-size: 16px;
    line-height: 24px;
  }
}

.ClinicalSupportNewPageTemplate .mt-30 {
  margin-top: 30px !important;
}

.ClinicalSupportNewPageTemplate #clinical-support .whatif--card-wide .cta {
  text-transform: capitalize;
}

.ClinicalSupportNewPageTemplate .whatif--card-wide .cta {
  font-family: "Campton-SemiBold", sans-serif;
  // color: #2a3237;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.ClinicalSupportNewPageTemplate .whatif--card-wide .cta img {
  width: 24px !important;
  height: auto;
  margin-left: 10px;
}

img {
  vertical-align: middle;
}

img {
  border: 0;
}

//flu- join360 container
.ClinicalSupportNewPageTemplate #join360-footer #join360-row {
  background-color: #ea181b !important;
  padding: 0px !important;
}

.ClinicalSupportNewPageTemplate #join360-footer #join360-row .join360-row-bg1 {
  background-color: white !important;
  top: 21px !important;
}

.ClinicalSupportNewPageTemplate #join360-footer #join360-row .join360-row-bg2 {
  background-color: white !important;
  top: 39px !important;
}

.ClinicalSupportNewPageTemplate #join360-footer #join360-row .join360--hero-container {
  margin-left: 0px !important;
}

.ClinicalSupportNewPageTemplate #join360-footer #join360-row .join360--hero-container .join360--hero-container--content .header {
  color: #ea181b !important;
  font-weight: 600 !important;
  letter-spacing: 0;
}

@media (min-width: 1024px) {
  .ClinicalSupportNewPageTemplate #join360-footer #join360-row .join360--hero-container .join360--hero-container--content .header {
    font-size: 42px !important;
    width: 700px !important;
  }
}

.AboutSeqirusPageTemplate {
  @media (min-width: 1024px) {
    .page-references .about-ref {
      width: 100%;
      word-break: initial;
    }
  }
}
.ClinicalSupportNewPageTemplate .container.breadcrumbs--page-nav.breadcrumbs-opeff.d-none
{
  display:none !important;
}
.ClinicalSupportNewPageTemplate .breadcrumbs--page-nav.codingnbilling-breadcrumbs.d-none{
  display:none !important;
}
.ClinicalSupportNewPageTemplate .breadcrumbs--page-nav{
  top:10px !important;
  right:15px !important;
}
.ClinicalSupportNewPageTemplate img.rectangle-down{
  right:0 !important
}

.ClinicalSupportNewPageTemplate p.header{
  background-color: white !important;

}
.ClinicalSupportNewPageTemplate {
  .hero--circlechart-content .global_greybtn {
    background-color: #ea181b;
    border: 1px solid #ea181b;
  }

  .home_desktopresourcessection {
    margin-bottom: 4em;
  }

  #home_resourcessection {
    .global_greybtn {
      padding: 17px 29px 17px 29px !important;
      border: 1px solid #5D6F7B !important;
    }

    .global_line {
      height: 2px !important;
    }
  }

  @media (min-width: 1024px) {
    #join360-footer #join360-row .join360--hero-container .join360--hero-container--content {
      padding-top: 50px !important;
    }
  }

  #home_clinicallink .global_blackarrow {
    width: 29px;
    height: 29px;
    top: 50%;
    right: -2px;
    position: absolute;
    transform: translateY(-50%);
    z-index: 3;
    margin: 0 !important;
  }

  .alertbox {
    position: relative !important;
  }

  .header {
    border-bottom: 1px solid #ececec;
  }

  #home_resourcessection {
    padding: 50px 1% 5% 1% !important;
    margin-top: 4% !important;
    margin-bottom: -3%;
  }

  .join360--hero-container--content {
    .global_redbtn {
      border: 1.5px solid #EA181B !important;
    }
    .global_greybtn{
      border: 1px solid #5D6F7B !important;
    }
  }
  .clinical-breadcrumbs{
    .container{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .content-container .text--red-100 {
    color: #ea181b;
    font-family: "Campton-Bold", sans-serif;
  }
  .hero--circlechart .hero--circlechart-desktop img {
    // left: 0 !important;
    top: -46px !important;
}
.home_resourcelink{
  border-top: 1px solid #eef0f1 !important;
}
#what-if-row {
.whatif--card-content .content{
 letter-spacing: 0;
}
.whatif--card{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
}
.outermost-div{
  padding-left: 10px;
}
// #multiple-needs-header h2{
//   font-size: 32px !important;
// }
#multiple-needs-header{
  margin-bottom: 20px !important;
}
#multiple-needs-header h2{
  letter-spacing: 0;
}
@media (min-width: 1025px) {
  #multiple-needs-header h2 {
      font-size: 32px !important;
      line-height: 40px !important;
  }
}
.clinical-breadcrumbs .container{
  padding-left: 5em !important;
  letter-spacing: 0 !important;

}
.breadcrumbs--page-nav p {
  padding-top: 20px !important;
  margin-left: 0;
}
.hero--circlechart .hero--circlechart-desktop img {
  width: 91.5% !important;
}
.pl-hero-100{
  top: 10px;
}
.global_greybtn:hover{
  color: #ea181b !important;
}
.ClinicalSupportNewPageTemplate .join-header {
  border-bottom: 1px solid white !important;
}
#join360-footer #join360-row .join360--hero-container .join360--hero-container--content .paragraph {
  letter-spacing: 0;
}
.opeff-login-button{
  color: #2a3237 !important;
}
.opeff-login-button:hover{
  color: white !important;
}
#home_resourcessection .global_greybtn:hover{
color: white !important;
}
.innovation--card-eyebrow span{
  text-transform: uppercase !important;
}
.global_dashheader{
  font-size: 24px !important;
}
// .home_resources{
//   padding-left: 15px !important;
//   padding-right: 15px !important;
// }
.home_resources:nth-of-type(2) {
  margin-left: 2% !important;
  margin-right: 2% !important;
}
#home_dashleftpadding{
  padding-left: 2% !important;
}
.home_desktopresourcessection{
  padding-left: 15px;
    padding-right: 15px;
}
}